import moon from "./moon.png";
import backgroundeffect from "./backgroundEffect.png";
import netbackgroundmoon from "./networkMoon.png";
import netbackgroundeffect from "./networkbg.png";
import netelllipse from "./Net_Ellipse.png";
import imgCta from "./img_cta.png";
import imgRectangle from "./Rectangle.png";

export const IMG_MOON = moon;
export const IMG_BACKGROUND_EFFECT = backgroundeffect;
export const IMG_NET_MOON = netbackgroundmoon;
export const IMG_NET_BG_EFFECT = netbackgroundeffect;
export const IMG_NET_ELLISPE = netelllipse;
export const IMG_CTA = imgCta;
export const IMG_RECTANGLE = imgRectangle;
