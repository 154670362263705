import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageSplashScreen from "./components/SplashScreen";
import "./index.css";
import { SnackbarProvider } from "notistack";
import { RecoilRoot } from "recoil";
import ModalProvider from "mui-modal-provider";
import { Analytics } from "@vercel/analytics/react";
import StaticHome from "./pages/home";
import ThemeManager from "./middlewares/Thememanger";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <RecoilRoot>
    {/* The theme provider is added at the root level  */}
    <ThemeManager>
      <ModalProvider>
        <SnackbarProvider
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          <PageSplashScreen>
            <BrowserRouter basename="/">
              <Routes>
                <Route path="/" element={<StaticHome />} />
              </Routes>
            </BrowserRouter>

            <Analytics />
          </PageSplashScreen>
        </SnackbarProvider>
      </ModalProvider>
    </ThemeManager>
  </RecoilRoot>
);
