import React from "react";

function LG_ASSISTLY() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="41"
      fill="none"
      viewBox="0 0 44 41"
    >
      <g fill="#fff" clipPath="url(#clip0_21930_2303)">
        <path d="M13.04 8.466h21.738s4.251-.16 1.739 2.56c0 0-11.497 10.642-10.918 15.363 0 0 .29 3.04 5.894 3.44h5.819s6.549-.56 6.258-4.88c0 0-1.191-11.984-3.894-19.41-.644-1.77-1.995-3.343-3.908-4.295-2.367-1.18-6.11-1.82-11.618.34 0 0-4.54 2-11.11 6.882"></path>
        <path d="M30.7 32.522H8.96s-4.25.16-1.739-2.56c0 0 11.497-10.643 10.918-15.364 0 0-.29-3.04-5.894-3.44H6.428s-6.549.56-6.258 4.88c0 0 1.191 11.985 3.894 19.41.643 1.77 1.995 3.343 3.907 4.295 2.368 1.181 6.111 1.82 11.62-.34-.002 0 4.539-2 11.108-6.881"></path>
      </g>
      <defs>
        <clipPath id="clip0_21930_2303">
          <path fill="#fff" d="M.16.159h43.39v40.635H.16z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LG_ASSISTLY;
