import React from "react";

function IC_DOT() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      fill="none"
      viewBox="0 0 8 8"
    >
      <g id="_Dot">
        <circle id="Dot" cx="4" cy="4" r="3" fill="#2E90FA"></circle>
      </g>
    </svg>
  );
}

export default IC_DOT;
