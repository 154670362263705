import React from "react";

function LG_ZEXWARE_CTA() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="98"
      height="86"
      fill="none"
      viewBox="0 0 98 86"
    >
      <g fill="#fff" clipPath="url(#clip0_21930_2378)">
        <path d="m28.424 20.404 43.087-2.258s8.382-.756 4.154 4.852c0 0-19.848 22.11-17.397 31.329 0 0 1.415 5.945 12.634 6.15l11.533-.604S95.26 58.09 93.491 49.629c0 0-5.672-23.43-13.08-37.745-1.765-3.412-4.877-6.362-8.931-8.035C66.46 1.774 58.865.907 48.544 5.725c0 0-8.445 4.403-20.12 14.679"></path>
        <path d="m70.074 65.85-43.087 2.257s-8.382.756-4.155-4.852c0 0 19.848-22.11 17.397-31.329 0 0-1.415-5.945-12.634-6.15l-11.53.604S3.242 28.162 5.01 36.623c0 0 5.672 23.43 13.08 37.745 1.765 3.413 4.878 6.363 8.932 8.036 5.02 2.074 12.615 2.942 22.935-1.876-.003 0 8.446-4.404 20.118-14.679"></path>
      </g>
      <defs>
        <clipPath id="clip0_21930_2378">
          <path
            fill="#fff"
            d="m.604 5.411 86-4.507L97.83 80.77l-86.001 4.507z"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LG_ZEXWARE_CTA;
