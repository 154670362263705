import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
import ColorHeading from "../../components/ColorHeading";
import ContactButton from "./ContactButton";

function Contact() {
  const theme = useTheme();
  return (
    <Stack
      spacing={"4px"}
      alignItems={"center"}
      className="contact"
      sx={{
        [theme.breakpoints.down("desktop")]: {},
        [theme.breakpoints.down("tablet")]: {
          width: "100%",
          alignItems: "center",
        },
      }}
    >
      <ColorHeading
        text2="Connect with Zexware  "
        text3="– Your Journey Starts Here"
        description="Ready to take the next step? Book a call or fill out our form to connect with our team. We’re here to turn your vision into reality."
        mainsx={{
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: {
            width: "100%",
            padding: "10px",
          },
        }}
      />
      <Stack
        sx={{
          width: "650px",
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: {
            width: "85%",
          },
        }}
      >
        <ContactButton />
      </Stack>
    </Stack>
  );
}

export default Contact;
