import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ColorHeading from "../../components/ColorHeading";
import IC_LINE from "./assets/icons/IC_LINE";

function FeaturesNum() {
  const theme = useTheme();
  const desktop = useMediaQuery("(min-width: 1024px)"); // we add spacings here
  return (
    <Stack
      sx={{
        alignItems: "center",
        padding: " 100px 0px",
        gap: "72px",
      }}
    >
      <ColorHeading
        text2="Our Performance"
        text3=" at a Glance"
        description="See the numbers behind our success. We focus on delivering exceptional
        customer satisfaction, measurable impact, and outstanding user
        experiences."
      />

      {/* The why choose us cards  */}
      <Stack
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          width: "1280px",
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: {
            flexDirection: "column",
            gap: "32px",
          },
        }}
      >
        <Stack
          sx={{
            width: "324px",
            alignItems: "center",
            gap: "32px",
            textAlign: "center",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {
              gap: "20px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "48px",
              fontWeight: 700,
              color: theme.palette.text.secondary,
            }}
          >
            90
            <span className="gradient-text" style={{}}>
              +
            </span>
          </Typography>
          <Typography>
            Client retention rate, driven by our commitment to delivering
            tailored solutions.
          </Typography>
          <Typography
            sx={{
              color: theme.palette.grey[100],
              fontWeight: "600",
              cursor: "pointer",
            }}
          >
            Client Satisfaction
          </Typography>
        </Stack>
        {desktop && <IC_LINE />}
        <Stack
          sx={{
            width: "324px",
            alignItems: "center",
            gap: "32px",
            textAlign: "center",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {
              gap: "20px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "48px",
              fontWeight: 700,
              color: theme.palette.text.secondary,
            }}
          >
            500
            <span className="gradient-text" style={{}}>
              +
            </span>
          </Typography>
          <Typography>
            Over 500 businesses transformed through our digital solutions,
            resulting in measurable growth
          </Typography>
          <Typography
            sx={{
              color: theme.palette.grey[100],
              fontWeight: "600",
              cursor: "pointer",
            }}
          >
            Business Impact
          </Typography>
        </Stack>
        {desktop && <IC_LINE />}
        <Stack
          sx={{
            width: "324px",
            alignItems: "center",
            gap: "32px",
            textAlign: "center",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {
              gap: "20px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "48px",
              fontWeight: 700,
              color: theme.palette.text.secondary,
            }}
          >
            40
            <span className="gradient-text" style={{}}>
              %
            </span>
          </Typography>
          <Typography>
            Our solutions reduce operational complexity, increasing business
            efficiency by 40% on average.
          </Typography>
          <Typography
            sx={{
              color: theme.palette.grey[100],
              fontWeight: "600",
              cursor: "pointer",
            }}
          >
            Efficiency
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default FeaturesNum;
