import React from "react";

function C_IMG_ZEXCORE() {
  return (
    <svg
      width="355"
      height="320"
      viewBox="0 0 355 320"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6" filter="url(#filter0_i_21930_2018)">
        <rect
          width="150"
          height="150"
          rx="40.0309"
          transform="matrix(0.866025 -0.5 0 1 80 107)"
          fill="#0BA5EC"
        />
      </g>
      <g opacity="0.8" filter="url(#filter1_i_21930_2018)">
        <rect
          width="150"
          height="150"
          rx="40.0309"
          transform="matrix(0.866025 -0.5 0 1 108 121)"
          fill="#0BA5EC"
        />
      </g>
      <g filter="url(#filter2_i_21930_2018)">
        <rect
          width="150"
          height="150"
          rx="40.0309"
          transform="matrix(0.866025 -0.5 0 1 145 138)"
          fill="#0BA5EC"
        />
        <g clip-path="url(#clip0_21930_2018)">
          <path
            d="M192.285 157.915L235.836 132.771C235.836 132.771 244.352 127.484 239.32 136.683C239.32 136.683 216.288 174.598 217.447 184.85C217.447 184.85 218.028 191.547 229.255 185.991L240.913 179.261C240.913 179.261 254.032 170.39 253.45 160.731C253.45 160.731 251.063 134.387 245.649 120.334C244.359 116.984 241.652 114.909 237.82 114.919C233.077 114.927 225.578 117.777 214.544 129.145C214.544 129.145 205.449 139.024 192.285 157.915Z"
            fill="white"
          />
          <path
            d="M227.669 193.135L184.118 218.279C184.118 218.279 175.602 223.566 180.634 214.367C180.634 214.367 203.666 176.452 202.507 166.201C202.507 166.201 201.926 159.503 190.699 165.059L179.045 171.787C179.045 171.787 165.926 180.658 166.507 190.317C166.507 190.317 168.894 216.661 174.308 230.714C175.598 234.064 178.305 236.139 182.137 236.129C186.881 236.122 194.379 233.271 205.414 221.903C205.41 221.905 214.508 212.024 227.669 193.135Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_21930_2018"
          x="80"
          y="32"
          width="129.904"
          height="225"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="19.1242" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_21930_2018"
          />
        </filter>
        <filter
          id="filter1_i_21930_2018"
          x="108"
          y="46"
          width="129.904"
          height="225"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="19.1242" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_21930_2018"
          />
        </filter>
        <filter
          id="filter2_i_21930_2018"
          x="145"
          y="63"
          width="129.904"
          height="225"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="19.1242" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_21930_2018"
          />
        </filter>
        <clipPath id="clip0_21930_2018">
          <rect
            width="100.373"
            height="94"
            fill="white"
            transform="matrix(0.866025 -0.5 0 1 166.488 153.593)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default C_IMG_ZEXCORE;
