import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import Marquee from "react-fast-marquee";
import ZButton from "../../components/ZButton";
import IC_PHONE from "./assets/icons/IC_PHONE";
import IC_SHAPE from "./assets/icons/IC_SHAPE";
import FloatingIcon from "../../components/FloatingIcon";
import LG_ZEXWARE_CTA from "./assets/logos/LG_ZEXWARE_CTA";
import LG_C_SELL_CTA from "./assets/logos/LG_C_SELL_CTA";
import LG_CASHIFY_CTA from "./assets/logos/LG_CASHIFY_CTA";

function CtaSection() {
  const theme = useTheme();
  const desktop = useMediaQuery("(min-width: 1024px)"); // we add spacings here

  return (
    <Stack
      className="call"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        gap: "56px",
        position: "relative",
        padding: "360px 0 200px 0",
        overflow: "hidden",
        [theme.breakpoints.down("desktop")]: { padding: "40px 0 40px 0 " },
        [theme.breakpoints.down("tablet")]: {},
      }}
    >
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          gap: "32px",
        }}
      >
        <Stack>
          <Typography
            className="gradient-text_colorheading"
            sx={{
              fontSize: "60px",
              letterSpacing: "-1.2px",
              lineHeight: "72px",
              fontweight: 600,
              display: "inline",
              color: "#000",
              textAlign: "center",
            }}
          >
            Ready to Take Your
            <Typography
              className="gradient-text-faq"
              sx={{
                fontSize: "60px",
                letterSpacing: "-1.2px",
                lineHeight: "72px",
                fontweight: 600,
                // display: "inline",
              }}
            >
              Business to the Next Level?
            </Typography>
          </Typography>
        </Stack>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        >
          <ZButton
            onClick={() => {
              const _elem = window.document.querySelector(".contact");
              if (_elem) {
                _elem.scrollIntoView({ behavior: "smooth" });
              } else {
                window.open("/");
              }
            }}
            label={"Get a Free Consultation"}
            endIcon={<IC_PHONE />}
          />
        </motion.div>
      </Stack>
      {/* logo slider */}
      <Stack
        sx={{
          width: "100%",
          paddingTop: "200px",
          gap: "32px",
          alignItems: "center",
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: {
            gap: "20px",
          },
        }}
      >
        <Marquee direction="right">
          <Stack
            sx={{
              alignItems: "center",
              flexDirection: "row",
              gap: "40px",
              display: "flex",
              flexWrap: "nowrap",
              overflow: "hidden",
              [theme.breakpoints.down("desktop")]: {},
              [theme.breakpoints.down("tablet")]: {
                gap: "20px",
              },
            }}
          >
            <Typography
              sx={{
                color: theme.palette.grey[50],
                fontWeight: 600,
                fontSize: "72px",
                letterSpacing: " -1.44px",
                textTransform: "uppercase",
                lineHeight: "90px",
                [theme.breakpoints.down("desktop")]: {},
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "36px",
                  lineHeight: "70px",
                },
              }}
            >
              web design
            </Typography>
            <IC_SHAPE />
            <Typography
              sx={{
                color: theme.palette.grey[50],
                fontWeight: 600,
                fontSize: "72px",
                letterSpacing: " -1.44px",
                textTransform: "uppercase",
                lineHeight: "90px",
                [theme.breakpoints.down("desktop")]: {},
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "36px",
                  lineHeight: "70px",
                },
              }}
            >
              zexware
            </Typography>
            <IC_SHAPE />
            <Typography
              sx={{
                color: theme.palette.grey[50],
                fontWeight: 600,
                fontSize: "72px",
                letterSpacing: " -1.44px",
                textTransform: "uppercase",
                lineHeight: "90px",
                [theme.breakpoints.down("desktop")]: {},
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "36px",
                  lineHeight: "70px",
                },
              }}
            >
              Web development
            </Typography>
          </Stack>
        </Marquee>

        <Marquee direction="left">
          <Stack
            sx={{
              alignItems: "center",
              flexDirection: "row",
              gap: "40px",
              display: "flex",
              flexWrap: "nowrap",
              overflow: "hidden",
            }}
          >
            <Typography
              sx={{
                color: theme.palette.grey[50],
                fontWeight: 600,
                fontSize: "72px",
                letterSpacing: " -1.44px",
                textTransform: "uppercase",
                lineHeight: "90px",
                [theme.breakpoints.down("desktop")]: {},
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "36px",
                  lineHeight: "70px",
                },
              }}
            >
              Software development
            </Typography>
            <IC_SHAPE />
            <Typography
              sx={{
                color: theme.palette.grey[50],
                fontWeight: 600,
                fontSize: "72px",
                letterSpacing: " -1.44px",
                textTransform: "uppercase",
                lineHeight: "90px",
                [theme.breakpoints.down("desktop")]: {},
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "36px",
                  lineHeight: "70px",
                },
              }}
            >
              98% Success rate
            </Typography>
          </Stack>
        </Marquee>
      </Stack>
      {/* zexweare */}
      {desktop && (
        <FloatingIcon
          initial={{
            top: 400,
            right: 50,
            transform: "rotateZ(-24deg)",
          }}
          animate={{
            right: 100,
            top: 400,
            transform: "rotateZ(-9.69deg)",
          }}
        >
          <LG_ZEXWARE_CTA />
        </FloatingIcon>
      )}
      {/* cashifysell */}
      {desktop && (
        <FloatingIcon
          style={{
            transform: "rotateZ(0)",
          }}
          initial={{
            top: 400,
            left: 50,
          }}
          animate={{
            left: 100,
            top: 400,
            transform: "rotateZ(-21.2deg)",
            background: "#7A5AF8",
          }}
        >
          <LG_C_SELL_CTA />
        </FloatingIcon>
      )}
      {/* cashifybot right*/}
      {desktop && (
        <FloatingIcon
          initial={{
            top: 200,
            right: 200,
            transform: "rotateZ(45deg)",
          }}
          animate={{
            right: 100,
            top: 100,
            transform: "rotateZ(0deg)",
            background: "#66C61C",
          }}
        >
          <LG_CASHIFY_CTA />
        </FloatingIcon>
      )}

      {/* <Stack
        sx={{
          position: "absolute",
          zIndex: "-0",
          width: "100%",
          bottom: "-52px",
          overflow: "hidden",
          top: "52px",
          pointerEvents: "none",
          opacity: "0.50",
        }}
      >
        <img src={IMG_BACKGROUND_GRID_LINES} alt="" />
      </Stack> */}
    </Stack>
  );
}

export default CtaSection;
