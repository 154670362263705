const debug = {
  build: "2024.5.18",
  server: {
    rtm: "ws://localhost:3001/",
    rest: "http://localhost:3001",
  },
  livechat: {
    rtm: "wss://livechat.zexware.com",
    rest: "https://livechat.zexware.com",
  },
  apiKey: "1a73a6f3-81e9-4d25-bdaa-43f0587d1db6",
  firebase: {
    apiKey: "AIzaSyAl_GAoae5sP3oE5hfOSniLoBrSRdCg3Fw",
    authDomain: "zexware-ltd.firebaseapp.com",
    projectId: "zexware-ltd",
    storageBucket: "zexware-ltd.appspot.com",
    messagingSenderId: "575038451355",
    appId: "1:575038451355:web:e63e159528074bf5fc203e",
  },
  discord: {
    webhook:
      "https://discord.com/api/webhooks/1177982816223510650/QSKMGbEh3cy9NJ7N56ykWyBC86nFRwf5UY756KGAnIpxNRo8RxJZf3HuZK4M2HdNOvvj",
  },
};

const prod = {
  build: "2024.5.30",
  server: {
    rtm: "wss://api.zexware.com/",
    rest: "https://api.zexware.com",
  },
  livechat: {
    rtm: "wss://livechat.zexware.com",
    rest: "https://livechat.zexware.com",
  },
  apiKey: "1a73a6f3-81e9-4d25-bdaa-43f0587d1db6",
  firebase: {
    apiKey: "AIzaSyAl_GAoae5sP3oE5hfOSniLoBrSRdCg3Fw",
    authDomain: "zexware-ltd.firebaseapp.com",
    projectId: "zexware-ltd",
    storageBucket: "zexware-ltd.appspot.com",
    messagingSenderId: "575038451355",
    appId: "1:575038451355:web:e63e159528074bf5fc203e",
  },
  discord: {
    webhook:
      "https://discord.com/api/webhooks/1177982816223510650/QSKMGbEh3cy9NJ7N56ykWyBC86nFRwf5UY756KGAnIpxNRo8RxJZf3HuZK4M2HdNOvvj",
  },
};

const DEBUG = process.env.REACT_APP_DEBUG === "true";

export const AppConfig = DEBUG ? debug : prod;
