import React from "react";

function BookCall() {
  return (
    <iframe
      title="Book a Call"
      src="https://cal.com/zexware/30min"
      height="600px"
      width="1000px"
      style={{
        border: "none",
        borderRadius: "12px",
      }}
    ></iframe>
  );
}

export default BookCall;
