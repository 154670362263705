import { Stack, Typography } from "@mui/material";
import React from "react";
import IC_CHECK from "../assets/icons/IC_CHECK";
import ZButton from "../../../components/ZButton";
import IC_PHONE from "../assets/icons/IC_CLEAN_PHONE";

function CustomPlan() {
  return (
    <Stack
      sx={{
        border: "1.5px solid #EAECF0",
        padding: "32px 32px  24px 32px",
        borderRadius: "24px",
        width: "100%",
      }}
      spacing={"24px"}
      direction={"row"}
    >
      <Stack width={"570px"}>
        {/* text */}
        <Stack spacing={"24px"}>
          <Stack
            direction={"row"}
            sx={{
              gap: "12px",
              alignItems: "center",
            }}
          >
            <Typography
              className="gradient-text_colorheading"
              sx={{
                fontSize: "36px",
                letterSpacing: "-0.72px",
                lineHeight: "44px",
                fontweight: 600,
              }}
            >
              Custom Plan
            </Typography>
          </Stack>
          <Typography>
            Designed for businesses with continuous development needs, offering
            flexibility and fast support to keep your projects on track.
          </Typography>
        </Stack>
        {/* Button */}
        <Stack
          sx={{
            gap: "12px",
            padding: "32px 0",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "90%",
          }}
        >
          <ZButton
            label="Book a Consultation"
            startIcon={<IC_PHONE />}
            sx={{
              background: "#fff",
              color: "#344054",
              width: "90%",

              border: "1px solid #D0D5DD",
              ":hover": {
                background: "#2E90FA",
              },
            }}
            onClick={() => {
              const _elem = window.document.querySelector(".contact");
              if (_elem) {
                _elem.scrollIntoView({ behavior: "smooth" });
              } else {
                window.open("/");
              }
            }}
          />
        </Stack>
      </Stack>
      {/* text with icons or  */}
      <Stack
        sx={{
          gap: "28px",
          padding: "32px",
        }}
      >
        <Stack
          sx={{
            gap: "16px",
          }}
        >
          <Stack direction={"row"} sx={{ gap: "12px" }}>
            <IC_CHECK />

            <Typography
              sx={{
                display: "inline",
                color: "#475467",
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              Personalized Development Roadmap
            </Typography>
          </Stack>
          <Stack direction={"row"} sx={{ gap: "12px" }}>
            <IC_CHECK />
            <Typography
              sx={{
                display: "inline",
                color: "#475467",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "28px",
              }}
            >
              Dedicated Project Lead
            </Typography>
          </Stack>
          <Stack direction={"row"} sx={{ gap: "12px" }}>
            <IC_CHECK />
            <Typography
              sx={{
                display: "inline",
                color: "#475467",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "28px",
              }}
            >
              Flexible Scope Adjustments
            </Typography>
          </Stack>
          <Stack direction={"row"} sx={{ gap: "12px" }}>
            <IC_CHECK />
            <Typography
              sx={{
                display: "inline",
                color: "#475467",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "28px",
              }}
            >
              Detailed Progress Updates{" "}
            </Typography>
          </Stack>
          <Stack direction={"row"} sx={{ gap: "12px" }}>
            <IC_CHECK />
            <Typography
              sx={{
                display: "inline",
                color: "#475467",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "28px",
              }}
            >
              Focused Testing & Quality Assurance
            </Typography>
          </Stack>
          <Stack direction={"row"} sx={{ gap: "12px" }}>
            <IC_CHECK />
            <Typography
              sx={{
                display: "inline",
                color: "#475467",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "28px",
              }}
            >
              Comprehensive Handover
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default CustomPlan;
