import React from "react";
import { Button, Stack, SxProps, Typography, useTheme } from "@mui/material";
import "../../../App.css";
import IC_ARROW from "../assets/icons/IC_ARROW";
function FeaturesCard(props: {
  img?: any;
  mainsx?: SxProps;
  heading?: string;
  description?: string;
  link?: any;
}) {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        border: "1.5px solid  #EAECF0",
        borderRadius: "24px",
        padding: "2px",
        ":hover": {
          background:
            "linear-gradient(45deg, #f5f5f5, #9a88fb, #f5f5f5, #1570ef)",
          borderRadius: "25px",
          padding: "2px",
          transition: " borderColor 0.3s ease-in-out;",
        },
      }}
    >
      <Stack
        sx={{
          background: "#fff",
          width: "320px",
          flex: 1,
          padding: " 24px 20px",
          alignItems: "center",
          position: "relative",
          borderRadius: "24px",
          ...props.mainsx,
        }}
      >
        <Stack>
          <Button
            sx={{
              display: "flex",
              height: "48px",
              position: "absolute",
              top: "14px",
              right: "14px",
              padding: "14px",
              background: "#1570EF",
              borderRadius: "16px",
              ":hover": {
                background: theme.palette.grey[100],
              },
            }}
            href={props.link}
          >
            <IC_ARROW />
          </Button>
        </Stack>

        {/* img */}
        <Stack>{props.img}</Stack>
        {/* text */}
        <Stack>
          <Typography
            sx={{
              color: "#000",
              fontSize: "30px",
              fontWeight: "600",
              textAlign: "center",
              lineHeight: "38px",
            }}
          >
            {/* Cashifybot */}
            {props.heading}
          </Typography>
          <Typography
            sx={{
              color: "#475467",
              fontSize: "16px",
              fontWeight: "400",
              textAlign: "center",
              lineHeight: "24px",
              textOverflow: "ellipsis",
            }}
          >
            {/* How do you create compelling presentations that wow your colleagues
        and impress your managers? */}
            {props.description}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default FeaturesCard;
