import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import React from "react";
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    desktop: true;
  }
}
// Set up theme colors and breakpoints.
let theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#475467",
    },
    secondary: {
      main: "#475467",
    },
    background: {
      default: "#F9FAFB",
      paper: "#0E0B34",
    },
    text: {
      secondary: "#1F2937",
    },
    grey: {
      "50": "#DEDEDE",
      "100": "#2E90FA",
      "200": "#000",
      "300": "#1570EF",
      "400": "#0D0D0D",
      "500": "#08080C",
      "600": "#656565",
      "700": "rgba(255, 255, 255, 0.50)",
      "800": "#7A5AF8",
      "900": "#16B364",
      A100: "#B0B0B0",
      A200: "#FAFAFA",
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      desktop: 1024,
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "Manrope",
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          ":before": {
            background: "#0000",
          },
          background: "#0000",
        },

        expanded: {
          background: "transparent",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          background: "#0000",
          borderRadius: "8px 8px 0px 0px",
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          background: "#0000",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#475467",
        },
      },
    },
  },
});

// Set up components that are affected by the theme options
theme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "8px",
          padding: "8px 16px",
        },
      },
    },
  },
});

export default function ThemeManager(props: any) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
}
