import React from "react";

function LG_UPTON() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="41"
      fill="none"
      viewBox="0 0 58 41"
    >
      <g filter="url(#filter0_b_21930_2311)">
        <path
          fill="#fff"
          d="M49.929 32.253c-1.785-2.36-4.028-3.555-6.934-2.723-2.783.796-4.229 2.788-4.459 5.738h-8.731V.006c.888 0 1.726-.015 2.561.003 3.49.068 7.026-.185 10.463.29 8.726 1.195 14.763 8.645 14.648 17.588-.074 5.777-2.36 10.411-6.893 13.921-.198.147-.41.278-.655.445m-12.187-4.22c4.084.537 7.767-1.321 9.753-4.85 2.121-3.769 1.788-8.958-.797-12.304-2.14-2.773-6.073-4.318-8.959-3.51.003 6.865.003 13.736.003 20.664"
        ></path>
      </g>
      <g filter="url(#filter1_b_21930_2311)">
        <path
          fill="#fff"
          d="M8.475 5.896c0 4.831-.035 9.66.02 14.49.019 1.477.175 2.97.473 4.414.372 1.796 1.567 2.976 3.373 3.245a12.2 12.2 0 0 0 3.609.003c2.07-.322 3.186-1.78 3.526-3.779.227-1.348.318-2.731.324-4.1.033-6.174.015-12.344.015-18.517V.286h7.876c.032.206.088.379.085.552-.012 7.846.06 15.692-.08 23.536-.076 4.333-1.702 7.986-5.712 10.125-3.066 1.634-6.392 1.857-9.773 1.6-2.222-.169-4.361-.661-6.306-1.812C2.367 32.193.8 28.844.626 24.914.422 20.313.58 15.7.58 11.016c3.299-.59 5.854-2.374 7.896-5.12"
        ></path>
      </g>
      <g filter="url(#filter2_bdd_21930_2311)">
        <path
          fill="#fff"
          d="M42.12 35.381c.005-1.515.979-2.51 2.46-2.517 1.508-.009 2.58 1.059 2.567 2.56-.011 1.404-1.127 2.552-2.502 2.576-1.434.02-2.532-1.119-2.526-2.619"
        ></path>
        <path
          stroke="#fff"
          strokeWidth="0.178"
          d="M44.58 32.953c.733-.004 1.354.253 1.79.688.437.435.694 1.053.689 1.781-.012 1.359-1.09 2.466-2.415 2.489-1.38.02-2.442-1.075-2.436-2.53.003-.738.241-1.342.652-1.762.41-.42 1-.663 1.72-.666Z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_b_21930_2311"
          width="106.337"
          height="113.931"
          x="-9.527"
          y="-39.331"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImageFix"
            stdDeviation="19.666"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_21930_2311"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_21930_2311"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_b_21930_2311"
          width="105.925"
          height="114.566"
          x="-38.81"
          y="-39.045"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImageFix"
            stdDeviation="19.666"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_21930_2311"
          ></feComposite>
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_21930_2311"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_bdd_21930_2311"
          width="11.197"
          height="11.305"
          x="39.034"
          y="29.779"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImageFix"
            stdDeviation="1.542"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_21930_2311"
          ></feComposite>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1.355"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0.733333 0 0 0 0 0.984314 0 0 0 0.6 0"></feColorMatrix>
          <feBlend
            in2="effect1_backgroundBlur_21930_2311"
            result="effect2_dropShadow_21930_2311"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1.355"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0.733333 0 0 0 0 0.984314 0 0 0 0.6 0"></feColorMatrix>
          <feBlend
            in2="effect2_dropShadow_21930_2311"
            result="effect3_dropShadow_21930_2311"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect3_dropShadow_21930_2311"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default LG_UPTON;
