import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import IC_DOT from "../assets/icons/IC_DOT";
import IC_CHECK from "../assets/icons/IC_CHECK";
import ZButton from "../../../components/ZButton";
import IC_ARROW_RIGHT from "../assets/icons/IC_ARROW_RIGHT";
import IC_CLEAN_PHONE from "../assets/icons/IC_CLEAN_PHONE";

function MonthlyPlan() {
  const theme = useTheme();
  const desktop = useMediaQuery("(min-width: 1024px)"); // we add spacings here
  return (
    <Stack
      spacing={"24px"}
      direction={"row"}
      sx={{
        border: "1.5px solid #EAECF0",
        padding: "32px 32px  24px 32px",
        borderRadius: "24px",
        width: "100%",

        [theme.breakpoints.down("desktop")]: {},
        [theme.breakpoints.down("tablet")]: {
          flexDirection: "column",
          padding: "0",
        },
      }}
    >
      {/* text */}
      <Stack
        sx={{
          width: "570px",
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: { width: "90%" },
        }}
      >
        {/* text */}
        <Stack
          spacing={"24px"}
          sx={
            {
              // [theme.breakpoints.down("desktop")]: {},
              // [theme.breakpoints.down("tablet")]: { flexDirection: "column" },
            }
          }
        >
          <Stack
            gap={"6px"}
            sx={{
              [theme.breakpoints.down("desktop")]: {},
              [theme.breakpoints.down("tablet")]: {},
            }}
          >
            <Stack
              direction={"row"}
              sx={{
                gap: "12px",
                alignItems: "center",
                [theme.breakpoints.down("desktop")]: {},
                [theme.breakpoints.down("tablet")]: {},
              }}
            >
              <Typography
                className="gradient-text_colorheading"
                sx={{
                  fontSize: "36px",
                  letterSpacing: "-0.72px",
                  lineHeight: "44px",
                  fontweight: 600,
                }}
              >
                Monthly Plan
              </Typography>

              <Stack
                direction={"row"}
                sx={{
                  color: "#175CD3",
                  border: " 1px solid #B2DDFF",
                  background: " #EFF8FF",
                  borderRadius: "16px",
                  width: "150px",
                  fontSize: "14px",
                  fontWeight: 500,
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "6px !important",
                  padding: "4px 12px 4px 10px",
                  height: "28px",
                }}
              >
                <IC_DOT /> 5 Spots Available
              </Stack>
            </Stack>
            <Typography
              sx={{
                [theme.breakpoints.down("desktop")]: {},
                [theme.breakpoints.down("tablet")]: {},
              }}
            >
              Designed for businesses with continuous development needs,
              offering flexibility and fast support to keep your projects on
              track.
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{
                fontSize: "48px",
                fontWeight: 600,
                color: "#101828",
                lineHeight: "60px",
                letterSpacing: "-0.96px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              $
              <Typography
                sx={{
                  fontSize: "72px",
                  fontWeight: 600,
                  color: "#101828",
                  display: "inline",
                  gap: "2px",
                  lineHeight: "90px",
                  letterSpacing: "-1.44px",
                  textAlign: "center",
                }}
              >
                3000
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 500,
                  color: "#475467",
                  display: "inline",
                }}
              >
                per month
              </Typography>
            </Typography>
          </Stack>
        </Stack>
        {/* Button */}
        <Stack
          direction={"row"}
          sx={{
            gap: "12px",
            padding: "32px 0",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {},
          }}
        >
          <ZButton
            label="Subscribe"
            endIcon={<IC_ARROW_RIGHT />}
            sx={{
              width: "40%",
              lineHeight: "28px",
              fontSize: "18px",
              fontWeight: 600,
              gap: "10px",
            }}
          />
          {/* <Button
            endIcon={<IC_ARROW_RIGHT />}
            sx={{
              width: "40%",
              lineHeight: "28px",
              fontSize: "18px",
              fontWeight: 600,
              background: "#1570EF",
              gap: "10px",
            }}
          >
            Subscribe
          </Button> */}
          <ZButton
            label="Book a Consultation"
            endIcon={<IC_CLEAN_PHONE />}
            sx={{
              background: "#fff",
              color: "#344054",
              width: "40%",
              border: "1px solid #D0D5DD",

              ":hover": {
                background: "#2E90FA",
              },
            }}
            onClick={() => {
              const _elem = window.document.querySelector(".contact");
              if (_elem) {
                _elem.scrollIntoView({ behavior: "smooth" });
              } else {
                window.open("/");
              }
            }}
          />
        </Stack>
      </Stack>
      {/* text with icons or  */}
      <Stack
        sx={{
          gap: "28px",
          padding: "32px",
        }}
      >
        <Stack
          sx={{
            gap: "16px",
            // [theme.breakpoints.down("desktop")]: {},
            // [theme.breakpoints.down("tablet")]: { flexDirection: "column" },
          }}
        >
          <Stack direction={"row"} sx={{ gap: "12px" }}>
            <IC_CHECK />

            <Typography
              sx={{
                display: "inline",
                color: "#475467",
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              Proactive Maintenance
            </Typography>
          </Stack>
          <Stack direction={"row"} sx={{ gap: "12px" }}>
            <IC_CHECK />
            <Typography
              sx={{
                display: "inline",
                color: "#475467",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "28px",
              }}
            >
              Priority Access
            </Typography>
          </Stack>
          <Stack direction={"row"} sx={{ gap: "12px" }}>
            <IC_CHECK />
            <Typography
              sx={{
                display: "inline",
                color: "#475467",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "28px",
              }}
            >
              Monthly Strategy Sessions
            </Typography>
          </Stack>
          <Stack direction={"row"} sx={{ gap: "12px" }}>
            <IC_CHECK />
            <Typography
              sx={{
                display: "inline",
                color: "#475467",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "28px",
              }}
            >
              Bug Fixing Guarantee
            </Typography>
          </Stack>
          <Stack direction={"row"} sx={{ gap: "12px" }}>
            <IC_CHECK />
            <Typography
              sx={{
                display: "inline",
                color: "#475467",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "28px",
              }}
            >
              Flexible Task Allocation
            </Typography>
          </Stack>
          <Stack direction={"row"} sx={{ gap: "12px" }}>
            <IC_CHECK />
            <Typography
              sx={{
                display: "inline",
                color: "#475467",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "28px",
              }}
            >
              Performance Tracking
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default MonthlyPlan;
