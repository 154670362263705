import {
  Avatar,
  AvatarGroup,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import { IMG_MOON } from "./assets/images";
import {
  IMG_CUSTOMER_2,
  IMG_CUSTOMER_3,
  IMG_DEVON,
  IMG_MATTHEW_FORWOOD,
} from "./assets/images/avatars";
import IC_ARROW_RIGHT from "./assets/icons/IC_ARROW_RIGHT_HERO";
import ZButton from "../../components/ZButton";
import LG_INSYNC from "./assets/logos/LG_INSYNC";
import LG_ZEXWARE from "./assets/logos/LG_ZEXWARE";
import LG_STUDY from "./assets/logos/LG_STUDY";
import LG_PFM from "./assets/logos/LG_PFM";
import LG_STUICON from "./assets/logos/LG_STUICON";
import LG_SEG from "./assets/logos/LG_SEG";
import React from "react";
import IC_PHONE from "./assets/icons/IC_PHONE";
function Hero() {
  const theme = useTheme();
  const desktop = useMediaQuery("(min-width: 1024px)"); // we add spacings here

  return (
    <Stack
      className="hero-section"
      // id="Home"
      alignItems={"center"}
      sx={{
        width: "100%",
        overflow: "hidden",
        zIndex: "3",
        paddingTop: "100px",
        position: "relative",
        [theme.breakpoints.down("desktop")]: {
          paddingTop: "100px",
        },
        [theme.breakpoints.down("tablet")]: {
          paddingTop: "100px",
        },
      }}
    >
      {" "}
      {/* Content Container : Width 1140px  */}
      <Stack
        alignItems={"center"}
        sx={{
          maxWidth: "1140px",
          width: "100%",
          pt: "92px",
          position: "relative",
          gap: "48px",
          [theme.breakpoints.down("desktop")]: {
            pt: "120px",
          },
          [theme.breakpoints.down("tablet")]: {
            pt: "0px",
          },
        }}
      >
        {/* Avatars */}

        <Stack
          direction={"row"}
          spacing={"16px"}
          sx={{
            zIndex: 10,
            justifyContent: "center",
            alignItems: "center",

            [theme.breakpoints.down("tablet")]: {
              pt: "0px",
              border: "none",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            },
          }}
        >
          {/* Icons  */}
          <AvatarGroup
            variant="circular"
            sx={{
              gap: "-11px",
              "& .MuiAvatar-root": {
                height: "44px",
                width: "44px",
                borderRadius: "50px",
                border: "2px solid #FFF",
              },
              [theme.breakpoints.down("desktop")]: {
                pt: "120px",
              },
              [theme.breakpoints.down("tablet")]: {
                "& .MuiAvatar-root": {
                  height: "35px",
                  width: "35px",
                  borderRadius: "10px",
                  border: "none",
                },
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              },
            }}
          >
            <Tooltip
              placement="top"
              arrow
              title={
                <Stack sx={{}}>
                  <Typography color="#2E90FA">Devon Edwards</Typography>
                  <Typography color="#000">CEO, PropFirmMasters</Typography>
                </Stack>
              }
            >
              <Avatar src={IMG_DEVON} />
            </Tooltip>
            <Tooltip
              placement="top"
              arrow
              title={
                <Stack>
                  <Typography color="#2E90FA">Emile Van Zyl</Typography>
                  <Typography color="#000">CEO, Community Juice Ltd</Typography>
                </Stack>
              }
            >
              <Avatar src={IMG_CUSTOMER_2} />
            </Tooltip>
            <Tooltip
              placement="top"
              arrow
              title={
                <Stack>
                  <Typography color="#2E90FA">Ance Kalyar</Typography>
                  <Typography color="#000">CEO, Cashifybot Ltd</Typography>
                </Stack>
              }
            >
              <Avatar src={IMG_CUSTOMER_3} />
            </Tooltip>
            <Tooltip
              placement="top"
              arrow
              title={
                <Stack>
                  <Typography color="#2E90FA">Mathew Forwood</Typography>
                  <Typography color="#000">CEO, QuantifyFx</Typography>
                </Stack>
              }
            >
              <Avatar src={IMG_MATTHEW_FORWOOD} />
            </Tooltip>
          </AvatarGroup>
          {/* Supporting Text  */}
          <Stack>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "700",
                color: "#fff",
                lineHeight: "32px",
              }}
            >
              450+
            </Typography>
            <Typography
              fontSize={14}
              lineHeight={"20px"}
              sx={{
                color: "#fff",
              }}
            >
              Satisfied Customers
            </Typography>
          </Stack>
        </Stack>

        {/* Hero Text  */}

        <Stack
          alignItems={"center"}
          sx={{ width: "100%", zIndex: 5, gap: "24px" }}
        >
          <Typography
            textAlign={"center"}
            sx={{
              color: "#F9FAFB ",
              fontSize: "72px",
              fontWeight: "600",
              display: "inline",
              lineHeight: "90px",
              letterSpacing: " -1.44px",
              [theme.breakpoints.down("desktop")]: {},
              [theme.breakpoints.down("tablet")]: {
                fontSize: "46px",
                lineHeight: "70px",
              },
            }}
          >
            Transform
            <span style={{ color: "#5899F3 " }}> Your Business</span>
            <br />
            <IC_ARROW_RIGHT />
            With Cutting-Edge
            <br /> Software
            <span style={{ color: "#5899F3 " }}> Development</span>
          </Typography>
          <Typography
            textAlign={"center"}
            sx={{
              color: "#B0B0B0",
              fontSize: "18px",
              maxWidth: "700px",
              [theme.breakpoints.down("desktop")]: {},
              [theme.breakpoints.down("tablet")]: {
                maxWidth: "90%",
                fontSize: "16px",
              },
            }}
          >
            From web and mobile applications to third-party integrations and
            Discord bot development, we craft scalable, custom solutions to
            drive your business forward. Specializing in React and modern
            technologies, we ensure seamless, future-proof digital experiences.
          </Typography>
        </Stack>
        {/*Hero  Buttons */}
        <Stack
          sx={{
            flexDirection: "row",
            gap: "12px",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {
              flexDirection: "column",
              gap: "12px",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          <motion.div
            whileInView={{ translateX: 0 }}
            initial={{ translateX: -300 }}
            viewport={{ once: true }}
            transition={{ type: "spring", stiffness: 40, delay: 0 }}
          >
            <ZButton
              label={"Explore Our Services"}
              sx={{
                background: " #000",

                border: "1px solid #1570EF",
                ":hover": {
                  color: "#fff",
                  // border: "1.2px solid #1570EF",
                  background: "#2E90FA",
                },
              }}
              onClick={() => {
                const _elem = window.document.querySelector(".Services");
                if (_elem) {
                  _elem.scrollIntoView({ behavior: "smooth" });
                } else {
                  window.open("/");
                }
              }}
            />
          </motion.div>
          <motion.div
            viewport={{ once: true }}
            whileInView={{ translateX: 0 }}
            initial={{ translateX: 300 }}
            transition={{ type: "spring", stiffness: 40, delay: 0 }}
          >
            <ZButton
              label={"Get a Free Consultation"}
              endIcon={<IC_PHONE />}
              onClick={() => {
                const _elem = window.document.querySelector(".contact");
                if (_elem) {
                  _elem.scrollIntoView({ behavior: "smooth" });
                } else {
                  window.open("/");
                }
              }}
            />
          </motion.div>
        </Stack>

        {/* logos and support text */}
        <Stack
          sx={{
            width: "850px",
            gap: "48px",
            padding: " 40px  0px 166px  0px",
            alignItems: "center",
            justifyContent: "center",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {
              width: "90%",
              fontSize: "16px",
              overflow: "hidden",
            },
          }}
        >
          <Typography
            textAlign={"center"}
            sx={{
              color: "#B0B0B0",
              fontSize: "18px",
              maxWidth: "700px",
              [theme.breakpoints.down("desktop")]: {},
              [theme.breakpoints.down("tablet")]: {
                maxWidth: "100%",
                fontSize: "16px",
              },
            }}
          >
            Delivering exceptional digital solutions and innovation to companies
            across various industries, driving their success and growth.
          </Typography>
          {/*company logos */}
          {/* {desktop && ( */}
          <Stack
            sx={{
              gap: "80px",
              [theme.breakpoints.down("desktop")]: {},
              [theme.breakpoints.down("tablet")]: {
                gap: "12px",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              },
            }}
          >
            <Stack
              sx={{
                justifyContent: "space-between",
                flexDirection: "row",
                [theme.breakpoints.down("desktop")]: {},
                [theme.breakpoints.down("tablet")]: {
                  width: "100%",
                  gap: "20px",
                  flexDirection: "column",
                },
              }}
            >
              <LG_INSYNC
                height={desktop ? "42px" : "35px"}
                width={desktop ? "137px" : "100px"}
              />
              <LG_ZEXWARE
                height={desktop ? "42px" : "35px"}
                width={desktop ? "137px" : "100px"}
              />
              <LG_STUDY
                height={desktop ? "42px" : "35px"}
                width={desktop ? "137px" : "110px"}
              />
            </Stack>
            <Stack
              sx={{
                justifyContent: "space-between",
                flexDirection: "row",
                gap: "80px",
                [theme.breakpoints.down("desktop")]: {},
                [theme.breakpoints.down("tablet")]: {
                  width: "100%",
                  gap: "20px",
                  flexDirection: "column",
                },
              }}
            >
              <LG_PFM
                height={desktop ? "36px" : "35px"}
                width={desktop ? "225px" : "150px"}
              />
              <LG_STUICON
                height={desktop ? "36px" : "35px"}
                width={desktop ? "139px" : "150px"}
              />
              <LG_SEG
                height={desktop ? "48px" : "35px"}
                width={desktop ? "230px" : "150px"}
              />
            </Stack>
          </Stack>
          {/* )s} */}
        </Stack>

        {/* background img moon */}
        <Stack
          sx={{
            position: "absolute",
            top: -100,
            zIndex: "1",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {
              top: -10,
            },
          }}
        >
          <img src={IMG_MOON} alt="" />
        </Stack>

        {/* background img lines */}
      </Stack>
      {/* <Stack
        sx={{
          position: "absolute",
          zIndex: "-1",
          width: "100%",
          bottom: "-52px",
          overflow: "hidden",
          pointerEvents: "none",
          opacity: "0.50",
        }}
      >
        <img src={IMG_BACKGROUND_GRID_LINES} alt="" />
      </Stack> */}
    </Stack>
  );
}

export default Hero;
