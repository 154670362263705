import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";

function TestimonialCard(props: {
  message?: string;
  name?: string;
  workAt?: string;
  avatar?: any;
}) {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        [theme.breakpoints.down("desktop")]: {},
        [theme.breakpoints.down("tablet")]: {
          width: "90%",
        },
      }}
    >
      <Stack
        sx={{
          border: " 1.5px solid #EAECF0",
          borderRadius: "24px",
          padding: "2px",

          ":hover": {
            background:
              "linear-gradient(45deg, #f5f5f5, #9a88fb, #f5f5f5, #1570ef)",
            borderRadius: "25.5px",
            padding: "2px",
            transition: " borderColor 0.3s ease-in-out;",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {},
          },
        }}
      >
        <Stack
          sx={{
            background: "#fff",
            width: "363px",
            padding: "32px 24px",
            borderRadius: "24px",
            gap: "40px",
            justifyContent: "center",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {
              width: "100%",
            },
          }}
        >
          <Typography
            sx={{
              color: theme.palette.grey[400],
              fontWeight: "400",
              lineHeight: "30px",
              fontSize: "20px",
            }}
          >
            {/* The mobile app they developed transformed our user experience. It's
        fast, intuitive, and essential for enhancing customer satisfaction. */}
            {props.message}
          </Typography>
          <Stack
            direction={"row"}
            sx={{
              gap: "16px",
            }}
          >
            {props.avatar}
            {/* <IC_AVATAR2 /> */}
            <Stack
              sx={{
                gap: "16px",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.grey[500],
                  fontWeight: "600",
                  lineHeight: "32px",
                  fontSize: "24px",
                }}
              >
                {/* Emily Wright */} {props.name}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.grey[600],
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "14px",
                }}
              >
                {/* CEO of AppVenture */} {props.workAt}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default TestimonialCard;
