import {
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

function ColorHeading(props: {
  text1?: string;
  text2?: string;
  text3?: string;
  text1sx?: SxProps;
  text2sx?: SxProps;
  mainsx?: SxProps;
  description?: string;
  desSx?: SxProps;
}) {
  const theme = useTheme();
  const desktop = useMediaQuery("(min-width: 1024px)"); // we add spacings here

  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        width: "900px",
        textAlign: "center",
        [theme.breakpoints.down("desktop")]: {},
        [theme.breakpoints.down("tablet")]: {
          maxWidth: "90%",
          flexWrap: "wrap",
          alignItems: "center",
        },
        ...props.mainsx,
      }}
    >
      <Typography
        sx={{
          fontSize: "48px",
          letterSpacing: "-1.2px",
          lineHeight: "60px",
          fontweight: 600,
          display: "inline",
          color: "#000",
          [theme.breakpoints.down("desktop")]: { fontSize: "36px" },
          [theme.breakpoints.down("tablet")]: {
            fontSize: "36px",
            display: "inline",
            lineHeight: "48px",
          },
          ...props.text1sx,
        }}
      >
        {/* Launch and Grow Your{" "} */}
        {props.text1}
        {/* color text */}
        <Typography
          className="gradient-text_colorheading"
          sx={{
            fontSize: "60px",
            letterSpacing: "-1.44px",
            lineHeight: "90px",
            fontweight: 600,
            display: "inline",
            [theme.breakpoints.down("desktop")]: { fontSize: "36px" },
            [theme.breakpoints.down("tablet")]: {
              fontSize: "36px",
              display: "inline",
              lineHeight: "48px",
            },
            ...props.text2sx,
          }}
        >
          {/* Digital Products */}
          {props.text2}{" "}
        </Typography>
        {props.text3} {/* Digital Products */}
      </Typography>
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 400,
          maxWidth: "900px",
          textAlign: "center",
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: {
            maxWidth: "100%",
            fontSize: "18px",
            textAlign: "center",
          },
          ...props.desSx,
        }}
      >
        {props.description}
      </Typography>
    </Stack>
  );
}

export default ColorHeading;
