import React from "react";

function LG_INSYNC(props: any) {
  const width = props.width || "137";
  const height = props.height || "42";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      // width="137"
      // height="42"
      fill="snone"
      viewBox="0 0 137 42"
    >
      <path
        fill="#B0B0B0"
        stroke="#B0B0B0"
        strokeWidth="0.31"
        d="M9.142 6.364C6.45 3.667 4.344 1.372 4.468 1.248 4.592 1.093 10.412 1 30.13 1L40.5 11.388l-.155 19.535-9.75.155-.155 9.767L10.628 41 .568 30.923l-.062-12.9C.475 10.52.568 5.031.754 4.907c.154-.124 2.476 1.984 10.029 9.581v16.59h19.502V11.233H14.033z"
      ></path>
      <path
        fill="#B0B0B0"
        d="M56.31 12.132V28h-2.875V12.132zm16.152 0V28h-2.557l-7.477-10.808h-.131V28h-2.875V12.132h2.573l7.468 10.816h.14V12.132zm11.755 4.362q-.108-1.014-.914-1.58-.798-.566-2.076-.566-.9 0-1.542.271-.644.272-.984.736a1.8 1.8 0 0 0-.349 1.062q0 .495.225.86.232.365.628.62.395.247.875.418t.969.287l1.487.372q.9.21 1.728.565a6 6 0 0 1 1.495.899q.667.542 1.054 1.31.387.766.387 1.797 0 1.395-.712 2.456-.714 1.054-2.061 1.65-1.34.59-3.247.59-1.851 0-3.215-.574-1.356-.573-2.123-1.674-.76-1.1-.821-2.68h2.828q.062.828.511 1.379.45.55 1.17.82.729.273 1.627.272.938 0 1.643-.279.713-.286 1.115-.79.404-.512.41-1.193-.007-.62-.363-1.023-.357-.41-1-.682a9.6 9.6 0 0 0-1.487-.496l-1.806-.465q-1.96-.503-3.099-1.526-1.13-1.03-1.13-2.735 0-1.403.758-2.456.768-1.053 2.085-1.635 1.317-.588 2.983-.589 1.688 0 2.96.59 1.278.58 2.006 1.618.729 1.031.752 2.371zm4.461-4.362h3.247l3.881 7.02h.155l3.882-7.02h3.247l-5.773 9.949V28h-2.866v-5.92zm29.487 0V28h-2.557l-7.477-10.808H108V28h-2.875V12.132h2.573l7.469 10.816h.139V12.132zm16.714 5.354h-2.897a3.6 3.6 0 0 0-.458-1.263 3.4 3.4 0 0 0-1.96-1.526 4.4 4.4 0 0 0-1.356-.202q-1.293 0-2.293.651-1 .644-1.565 1.89-.566 1.24-.566 3.03 0 1.821.566 3.068.573 1.24 1.565 1.875.999.628 2.286.628.713 0 1.332-.186.628-.195 1.124-.566.503-.372.844-.914.35-.542.481-1.24l2.897.016a6.2 6.2 0 0 1-.705 2.123 6.3 6.3 0 0 1-1.402 1.75 6.5 6.5 0 0 1-2.03 1.179q-1.162.418-2.58.418-2.093 0-3.735-.968t-2.587-2.798-.946-4.385q0-2.565.953-4.385.954-1.83 2.596-2.797 1.643-.969 3.719-.969a7.9 7.9 0 0 1 2.464.372 6.3 6.3 0 0 1 2.03 1.093 5.8 5.8 0 0 1 1.464 1.75q.58 1.032.759 2.356"
      ></path>
    </svg>
  );
}

export default LG_INSYNC;
