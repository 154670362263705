import { Stack, useTheme } from "@mui/material";
import React from "react";
import ColorHeading from "../../components/ColorHeading";
import TestimonialCard from "./Cards/TestimonialCard";
import EmilyWright from "./assets/icons/IC_AVATAR1";
import MichaelHarris from "./assets/icons/IC_AVATAR2";
import JamesCooper from "./assets/icons/IC_AVATAR3";
import JerryTang from "./assets/icons/IC_AVATAR4";
import SarahThompson from "./assets/icons/IC_AVATAR5";
import JohnMartinez from "./assets/icons/IC_AVATAR6";
import MelissaGrant from "./assets/icons/IC_AVATAR7";
import AndrewScott from "./assets/icons/IC_AVATAR8";
import DavidLee from "./assets/icons/IC_AVATAR9";
import { IMG_RECTANGLE } from "./assets/images";

function Testimonials() {
  const theme = useTheme();

  return (
    <Stack
      alignItems={"center"}
      sx={{
        gap: "72px",
        padding: "0px 0px 80px 0px",
        position: "relative",
      }}
    >
      <ColorHeading
        text1="What Our  "
        text2="Clients Are Saying"
        description="Hear from the businesses that trust Zexware to transform their processes and deliver exceptional results."
      />
      <Stack
        sx={{
          gap: "24px",
          flexDirection: "row",
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: {
            flexDirection: "column",
          },
        }}
      >
        {/* row 1 */}
        <Stack
          sx={{
            gap: "16px",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {
              alignItems: "center",
            },
          }}
        >
          <TestimonialCard
            name="Emily Wright"
            workAt="CEO of AppVenture"
            message="The mobile app they developed transformed our user experience. It's fast, intuitive, and essential for enhancing customer satisfaction."
            avatar={<EmilyWright />}
          />
          <TestimonialCard
            name="Michael Harris"
            workAt="Senior Developer at Nexus Digital"
            message="The API integration they delivered was flawless. Their understanding of complex systems and ability to implement scalable solutions has been invaluable for us. We now manage data flow seamlessly, saving both time and resources."
            avatar={<MichaelHarris />}
          />

          <TestimonialCard
            name="James Cooper"
            workAt="CTO at DataStream Solutions"
            message="Their bot development services exceeded our expectations. The custom bots they delivered have significantly improved how we manage operations, leading to noticeable improvements in overall productivity."
            avatar={<JamesCooper />}
          />
        </Stack>
        {/* row 2 */}
        <Stack
          sx={{
            gap: "16px",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {
              alignItems: "center",
            },
          }}
        >
          <TestimonialCard
            name="Jerry Tang"
            workAt="Recent graduate, Marketing at Sweatpals"
            message="Their attention to detail and customer service made the entire process seamless and enjoyable"
            avatar={<JerryTang />}
          />

          <TestimonialCard
            name="Sarah Thompson"
            workAt="CTO at Tech Innovators"
            message="The team's expertise in web development truly exceeded our expectations. They delivered a flawless product and ensured that it perfectly aligned with our business goals. Their attention to detail and commitment to quality made all the difference."
            avatar={<SarahThompson />}
          />
          <TestimonialCard
            name="Andrew Scott"
            workAt=" IT Director at SoftWave Solutions"
            message="The desktop application they built for us has been a game-changer. It’s reliable, highly efficient, and exactly what we needed to simplify and improve our internal business processes.."
            avatar={<AndrewScott />}
          />
        </Stack>
        {/* row 3 */}
        <Stack
          sx={{
            gap: "16px",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {
              alignItems: "center",
            },
          }}
        >
          <TestimonialCard
            name="Melissa Grant"
            workAt="Founder of BotBoost Enterprises"
            message="Zexware  has been a game-changer for our automation needs. The custom bots they developed have significantly improved our operational efficiency, allowing us to focus on more strategic tasks. Their technical expertise ensured our success."
            avatar={<MelissaGrant />}
          />
          <TestimonialCard
            name="John Martinez"
            workAt="John Martinez"
            message="The desktop application they built for us has been a game-changer. It’s reliable, highly efficient, and exactly what we needed to simplify and improve our internal business processes."
            avatar={<JohnMartinez />}
          />
          <TestimonialCard
            name="David Lee"
            workAt="Operations Manager at FlowTech Systems"
            message="Their innovative approach to desktop development brought our project to life in ways we hadn’t imagined. The team didn’t just create a functional app but optimized our workflows with smart design choices. We couldn’t be happier with the results."
            avatar={<DavidLee />}
          />
        </Stack>
      </Stack>
      {/* <ZButton
        label="Load more"
        endIcon={<IC_ARROW_DOWN />}
        sx={{
          background: "#fff",
          color: "#344054",
          border: "1px solid #D0D5DD",
          zIndex: "1",
          fontSize: "18px",
          fontWeight: "600",
          padding: "16px 22px",

          ":hover": {
            background: "#2E90FA",
          },
        }}
      /> */}
      <Stack
        sx={{
          position: "absolute",
          bottom: "0",
          pointerEvents: "none",
          width: "100%",
        }}
      >
        <img src={IMG_RECTANGLE} alt="" style={{ width: "100%" }} />
      </Stack>
    </Stack>
  );
}

export default Testimonials;
