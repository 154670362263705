import React from "react";

function IC_ARROW_RIGHT_HERO() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="166"
      height="59"
      fill="none"
      viewBox="0 0 166 59"
    >
      <rect width="133" height="59" fill="#2E90FA" rx="29.5"></rect>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4.659"
        d="M42 29h121m0 0-11.737-13.976M163 29l-11.737 13.976"
      ></path>
    </svg>
  );
}

export default IC_ARROW_RIGHT_HERO;
