import axios from "axios";

export async function SendWebhookMessage(
  hook: string,
  title: string,
  text: string,
  color: number,
  fields: { name: string; value: any; inline?: boolean }[],
  files: string[]
) {
  try {
    if (Boolean(hook) === false) throw new Error("Invalid webhook address.");
    await axios.post(hook, {
      content: null,
      embeds: [
        {
          title: title,
          description: text,
          color: color,
          fields: fields,
        },
      ],
      username: "Zexware Contact Form",
      files: files,
    });
  } catch (err: any) {}
}
