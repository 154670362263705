import React from "react";
import { Button, Stack, SxProps, Typography, useTheme } from "@mui/material";
import "../../../App.css";
import IC_ARROW from "../assets/icons/IC_ARROW";
function ServicesCard(props: {
  img?: any;
  mainsx?: SxProps;
  heading?: string;
  description?: string;
  link?: any;
}) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        [theme.breakpoints.down("desktop")]: {},
        [theme.breakpoints.down("tablet")]: {
          width: "90%",
        },
      }}
    >
      <Stack
        sx={{
          border: "1.5px solid  #EAECF0",
          borderRadius: "25px",
          padding: "2px",

          ":hover": {
            background:
              "linear-gradient(45deg, #f5f5f5, #9a88fb, #f5f5f5, #1570ef)",
            borderRadius: "24px",
            padding: "2px",
            // transition: " borderColor 0.3s ease-in-out;",
          },
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: {
            width: "100%",
          },
        }}
      >
        <Stack
          sx={{
            width: "400px",
            height: "300px",
            borderRadius: "23px",
            padding: " 24px 20px 40px 20px",
            justifyContent: "space-between",
            position: "relative",
            background: "#FFF",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: { width: "100%" },
            ...props.mainsx,
          }}
        >
          <Stack>
            {/* icon */}
            <Stack
              sx={{
                width: "64px",
                height: "64px",
                padding: "12px",
                borderRadius: "14px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {props.img}
            </Stack>
            <Button
              sx={{
                height: "48px",
                position: "absolute",
                top: "14px",
                right: "14px",
                padding: "14px",
                background: theme.palette.grey[300],
                borderRadius: "16px",
                ":hover": {
                  background: theme.palette.grey[100],
                },
              }}
              onClick={props.link}
            >
              <IC_ARROW />
            </Button>
          </Stack>
          <Stack sx={{ gap: "8px" }}>
            {/* heading */}
            <Typography
              sx={{
                color: theme.palette.grey[200],
                fontSize: "24px",
                fontWeight: "600",
                lineHeight: "38px",
              }}
            >
              {/* Cashifybot */}
              {props.heading}
            </Typography>
            {/* description */}
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                textOverflow: "ellipsis",
              }}
            >
              {/* How do you create compelling presentations that wow your colleagues
          and impress your managers? */}
              {props.description}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ServicesCard;
