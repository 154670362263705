import React from "react";
const IC_API_DEV = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="#2E90FA"
      fillRule="evenodd"
      d="M23.632.896c-2.015-.27-4.596-.27-7.882-.27h-.172c-3.286 0-5.867 0-7.882.27-2.065.278-3.703.858-4.99 2.146S.837 5.967.56 8.032c-.271 2.015-.271 4.596-.27 7.882v.172c-.001 3.286-.001 5.867.27 7.882.278 2.065.858 3.704 2.145 4.99 1.288 1.288 2.926 1.869 4.991 2.146 2.015.271 4.596.271 7.882.271h.172c3.286 0 5.867 0 7.882-.27 2.065-.278 3.703-.859 4.99-2.146s1.869-2.926 2.146-4.99c.271-2.016.271-4.597.271-7.883v-.172c0-3.286 0-5.867-.27-7.882-.278-2.065-.859-3.703-2.146-4.99-1.288-1.288-2.926-1.868-4.99-2.146m-6.228 9.48h2.01a3.375 3.375 0 0 1 0 6.75H17.54V20.5a1.125 1.125 0 0 1-2.25 0v-8.01c-.002-.202-.004-.526.092-.82a1.88 1.88 0 0 1 1.203-1.203c.294-.096.618-.094.82-.092m.136 4.5h1.875a1.125 1.125 0 0 0 0-2.25H17.54zm-8.237-4.5c.469.006.885.302 1.044.743l2.429 6.724.927 2.225a1.125 1.125 0 1 1-2.077.865l-.65-1.557h-3.5l-.494 1.48a1.125 1.125 0 1 1-2.135-.711l.75-2.25.019-.052 2.625-6.75c.17-.437.593-.723 1.062-.717m-.994 6.75h1.816l-.875-2.421zm18.23-5.625a1.125 1.125 0 0 0-2.25 0v9a1.125 1.125 0 0 0 2.25 0z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default IC_API_DEV;
