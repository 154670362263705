import React, { useEffect, useState } from "react";
import { Button, useTheme } from "@mui/material";

export default function LinkButtonFooter(props: {
  label?: string;
  href?: string;
  onClick?: any;
  active?: boolean;
  observable?: string;
}) {
  const [active, setActive] = useState(props.active);
  const theme = useTheme();
  useEffect(() => {
    if (props.observable) {
      const el = document.querySelector(props.observable);
      if (!el) return;
      const observer = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // Set active state.
            setActive(true);
            return;
          } else {
            // Set inactive state.
            setActive(false);
          }
        },
        {
          root: null,
          threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
        }
      );

      observer.observe(el!);
    }
  }, []);

  return (
    <Button
      onClick={props.onClick}
      variant="text"
      disableElevation
      disableRipple
      disableTouchRipple
      href={props.href}
      sx={{
        p: 0,
        textAlign: "start",
        justifyContent: "start",
        fontWeight: "600",
        fontSize: "16px",
        color: theme.palette.grey.A100,
        ":hover": {
          color: "#66C61C",
          background: "transparent",
        },
      }}
    >
      {props.label}
    </Button>
  );
}
