import React from "react";
import {
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router";
import LinkButtonFooter from "../../components/LinkButtonFooter";
import IC_FACEBOOK from "./assets/icons/IC_FACEBOOK";
import IC_LINKDIN from "./assets/icons/IC_LINKDIN";
import { LOGO_HEADER_PNG } from "./assets";
import { Facebook, LinkedIn, Twitter } from "@mui/icons-material";
import LogoPlain from "./assets/logos/LogoPlain";
import LG_ZEXWARE from "./assets/logos/LG_ZEXWARE";
// import IC_YOUTUBE from "../Assets/icons/IC_YOUTUBE";
export default function Footer() {
  // const desktop = useMediaQuery("(min-width: 720px)");

  const nav = useNavigate();
  const theme = useTheme();
  return (
    <Stack
      id="footer"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid  #2E2E2E",
        [theme.breakpoints.down("desktop")]: {},
        [theme.breakpoints.down("tablet")]: {
          padding: "24px 18px ",
        },
      }}
    >
      <Stack
        sx={{
          gap: "40px",
          flexWrap: "wrap",
          padding: "  64px 80px 80px 80px",
          alignSelf: "center",
          justifyContent: "space-between",
          maxWidth: "1480px",
          width: "100%",
          [theme.breakpoints.down("desktop")]: {
            padding: "0",
            // justifyContent: "center",
          },
          //
          [theme.breakpoints.down("tablet")]: {
            gap: "32px",
            flexDirection: "column",
          },
        }}
      >
        <Stack
          sx={{
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",

            //
            [theme.breakpoints.down("desktop")]: {
              flexDirection: "row",
            },
            //
            [theme.breakpoints.down("tablet")]: {
              gap: "24px",
            },
          }}
        >
          {/* logo  text and   */}

          <Stack
            sx={{
              gap: "16px",
              [theme.breakpoints.down("desktop")]: {},
              [theme.breakpoints.down("tablet")]: {
                alignItems: "center",
              },
            }}
          >
            <LG_ZEXWARE />
            {/* text */}
            <Typography
              width={"354px"}
              sx={{
                fontSize: "15px",
                fontWeight: "400",
                color: "#B0B0B0",
                [theme.breakpoints.down("desktop")]: {},
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "13px",
                  textAlign: "center",
                },
              }}
            >
              Transform your business with cutting edge Software Development.
            </Typography>
          </Stack>
          {/* nav button  */}
          <Stack
            sx={{
              flexDirection: "row",
              gap: "64px",
              [theme.breakpoints.down("desktop")]: {},
              [theme.breakpoints.down("tablet")]: {
                flexWrap: "wrap",
                // flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <Stack
              sx={{
                alignItems: "start",
                justifyContent: "start",
                [theme.breakpoints.down("desktop")]: {},
                [theme.breakpoints.down("tablet")]: {
                  gap: "40px",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
            >
              <Stack
                sx={{
                  alignItems: "start",
                  justifyContent: "start",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  gap: "8px",
                  [theme.breakpoints.down("desktop")]: {},
                  [theme.breakpoints.down("tablet")]: {
                    alignItems: "center",
                    justifyContent: "center",
                  },
                }}
              >
                <Typography
                  color="#FFF"
                  sx={{ fontSize: "18px", fontWeight: "600" }}
                >
                  Company
                </Typography>{" "}
                <LinkButtonFooter
                  label="Home"
                  observable=".hero-section"
                  onClick={() => {
                    const _elem =
                      window.document.querySelector(".hero-section");
                    if (_elem) {
                      _elem.scrollIntoView({ behavior: "smooth" });
                    } else {
                      window.open("/");
                    }
                  }}
                />
                <LinkButtonFooter
                  label="Our Services"
                  observable=".Services"
                  onClick={() => {
                    const _elem = window.document.querySelector(".Services");
                    if (_elem) {
                      _elem.scrollIntoView({ behavior: "smooth" });
                    } else {
                      window.open("/");
                    }
                  }}
                />
                <LinkButtonFooter
                  label="Get a Quote"
                  observable=".contact"
                  onClick={() => {
                    const _elem = window.document.querySelector(".contact");
                    if (_elem) {
                      _elem.scrollIntoView({ behavior: "smooth" });
                    } else {
                      window.open("/");
                    }
                  }}
                />
              </Stack>
            </Stack>
            <Stack sx={{ alignItems: "start", justifyContent: "start" }}>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  gap: "8px",
                  alignItems: "start",
                  justifyContent: "start",
                  [theme.breakpoints.down("desktop")]: {},
                  [theme.breakpoints.down("tablet")]: {
                    alignItems: "center",
                    justifyContent: "center",
                  },
                }}
              >
                <Typography
                  color="#FFF"
                  sx={{ fontSize: "18px", fontWeight: "600" }}
                >
                  Services
                </Typography>{" "}
                <LinkButtonFooter label="Web Development" />
                <LinkButtonFooter label="Mobile App Development" />
                <LinkButtonFooter label="Custom Software Solutions" />
                <LinkButtonFooter label="API Integrations" />
                <LinkButtonFooter label="Automation Tools" />
              </Stack>
            </Stack>
            <Stack
              sx={{
                [theme.breakpoints.down("desktop")]: {},
                [theme.breakpoints.down("tablet")]: {
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
            >
              <Stack
                sx={{
                  alignItems: "start",
                  justifyContent: "start",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  gap: "8px",
                  [theme.breakpoints.down("desktop")]: {},
                  [theme.breakpoints.down("tablet")]: {
                    alignItems: "center",
                    justifyContent: "center",
                  },
                }}
              >
                <Typography
                  color="#FFF"
                  sx={{ fontSize: "18px", fontWeight: "600" }}
                >
                  Support
                </Typography>{" "}
                <LinkButtonFooter label="Help Center" />
                <LinkButtonFooter label="FAQs" />
                <LinkButtonFooter label="Privacy Policy" />
                <LinkButtonFooter label="Terms & Conditions" />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {/* social icons */}
        <Stack
          sx={{
            gap: "60px",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "500",

              color: theme.palette.grey.A100,
            }}
          >
            Follow us on Social Media
          </Typography>
          <Stack
            sx={{
              gap: "16px",
              flexDirection: "row",
            }}
          >
            <IconButton href="https://linkedin.com/company/zexware">
              <LinkedIn />
            </IconButton>
            <IconButton href="https://facebook.com/zexwareofficial">
              <Facebook />
            </IconButton>
            <IconButton href="https://x.com/zexware">
              <Twitter />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      {/* copyright text */}
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          flexDirection: "row",
          px: "80px",
          pb: "28px",
          maxWidth: "1480px",
          width: "100%",
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: {
            padding: "20px 0",
            gap: "10px",
          },
        }}
      >
        <Typography
          textAlign={"center"}
          sx={{ color: theme.palette.grey.A100 }}
        >
          Copyright &copy; @2024 Zexware, All Right Reserved
        </Typography>
        <Stack>
          <nav
            style={{
              alignItems: "start",
              justifyContent: "start",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "8px",
            }}
          >
            <LinkButtonFooter
              label="Privacy"
              // href="https://documenter.getpostman.com/view/23844318/2sA3BobCFF"
            />
            <LinkButtonFooter
              label="Terms"
              // href="https://youtube.com/channel/UCewTH2sb2knfPGlJC7KTWGA"
            />
            <LinkButtonFooter
              label="Cookies"
              // href="https://t.me/CashifybotOfficial"
            />
            <LinkButtonFooter
              label="FAQs"
              // href="https://t.me/CashifySupportBot"
            />
          </nav>
        </Stack>
      </Stack>
    </Stack>
  );
}
