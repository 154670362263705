import React, { useEffect, useState } from "react";
import { Button, Stack, useTheme } from "@mui/material";

export default function LinkButton(props: {
  label?: string;
  href?: string;
  onClick?: any;
  active?: boolean;
  observable?: string;
}) {
  const [active, setActive] = useState(props.active);

  useEffect(() => {
    if (props.observable) {
      const el = document.querySelector(props.observable);
      if (!el) return;
      const observer = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // Set active state.
            setActive(true);
            return;
          } else {
            // Set inactive state.
            setActive(false);
          }
        },
        {
          root: null,
          threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
        }
      );

      observer.observe(el!);
    }
  }, []);
  const theme = useTheme();
  return (
    <Stack
      style={{
        transition: "all .3s",
        background: active
          ? "linear-gradient(45deg, #1570EF, #2E2E2E, #9A88FB, #2E2E2E)"
          : "#0000",
        borderRadius: "24px",
        padding: "2px",
      }}
    >
      <Button
        onClick={props.onClick}
        variant="text"
        disableElevation
        disableRipple
        disableTouchRipple
        href={props.href}
        sx={{
          fontWeight: "600",
          fontSize: "16px",
          background: "#000",
          color: "  #D0D5DD",
          borderRadius: "24px",
          ":hover": {
            color: "#2E90FA",
            background: "#000",
          },
          [theme.breakpoints.down("desktop")]: { fontSize: "16px" },
          [theme.breakpoints.down("tablet")]: { fontSize: "12px" },
        }}
      >
        {props.label}
      </Button>
    </Stack>
  );
}
