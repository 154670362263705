import React from "react";

function IC_DESKTOP_DEV() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <path
        fill="#2E90FA"
        fillRule="evenodd"
        d="M13.579.5h6.17c2.387 0 4.298 0 5.824.16 1.571.166 2.908.516 4.073 1.332a8.3 8.3 0 0 1 2.026 2.026c.816 1.165 1.166 2.502 1.331 4.073.161 1.526.161 3.437.161 5.824v.17c0 2.387 0 4.297-.16 5.824-.166 1.57-.516 2.907-1.332 4.073a8.3 8.3 0 0 1-2.026 2.026c-1.165.816-2.502 1.166-4.073 1.331-1.526.16-3.437.16-5.824.16h-6.17c-2.387 0-4.297 0-5.824-.16-1.571-.165-2.908-.515-4.073-1.331a8.3 8.3 0 0 1-2.026-2.026C.84 22.816.491 21.48.325 19.909c-.161-1.527-.161-3.437-.16-5.824v-.17C.163 11.528.163 9.617.324 8.09.49 6.52.84 5.183 1.656 4.018a8.3 8.3 0 0 1 2.026-2.026C4.847 1.176 6.184.826 7.755.66 9.282.5 11.192.5 13.58.5m-5.51 3.144c-1.323.14-2.088.4-2.666.805a5.3 5.3 0 0 0-1.29 1.29c-.404.578-.665 1.342-.805 2.666-.142 1.351-.144 3.103-.144 5.595s.002 4.244.144 5.594c.14 1.324.4 2.09.806 2.667.35.502.787.938 1.289 1.29.578.404 1.343.665 2.667.805 1.35.142 3.102.144 5.594.144h6c2.492 0 4.244-.002 5.595-.144 1.323-.14 2.088-.4 2.666-.806a5.2 5.2 0 0 0 1.29-1.289c.404-.578.665-1.343.805-2.667.142-1.35.144-3.102.144-5.594s-.002-4.244-.144-5.595c-.14-1.324-.4-2.088-.805-2.666a5.3 5.3 0 0 0-1.29-1.29c-.578-.404-1.343-.665-2.666-.805-1.351-.142-3.103-.144-5.595-.144h-6c-2.492 0-4.244.002-5.594.144"
        clipRule="evenodd"
      ></path>
      <path
        fill="#2E90FA"
        d="M20.91 24.875h-8.492l.573 1.521c.312.83.37 1.75.216 2.589-.102.547-.152.821-.57 1.168-.416.347-.826.347-1.646.347H9.164a1.5 1.5 0 1 0 0 3h15a1.5 1.5 0 0 0 0-3h-1.827c-.82 0-1.23 0-1.647-.347s-.467-.62-.569-1.168a4.9 4.9 0 0 1 .216-2.589z"
      ></path>
      <path
        fill="#2E90FA"
        fillRule="evenodd"
        d="M21.528 10.02a1.5 1.5 0 0 0 .157 2.116l1.84 1.586q.182.156.32.278-.138.121-.32.278l-1.84 1.586a1.5 1.5 0 0 0 1.958 2.272l1.84-1.586.056-.047c.337-.291.696-.6.958-.896.297-.335.667-.867.667-1.607s-.37-1.272-.667-1.607c-.262-.296-.62-.605-.958-.896l-.056-.047-1.84-1.586a1.5 1.5 0 0 0-2.115.157M11.8 10.02a1.5 1.5 0 0 1-.157 2.116l-1.84 1.586-.32.278.32.278 1.84 1.586a1.5 1.5 0 1 1-1.958 2.272l-1.84-1.586-.056-.047c-.337-.291-.696-.6-.958-.896-.297-.335-.667-.867-.667-1.607s.37-1.272.667-1.607c.262-.296.62-.605.958-.896l.056-.047 1.84-1.586a1.5 1.5 0 0 1 2.115.157M18.639 8.077a1.5 1.5 0 0 1 .949 1.898l-3 9a1.5 1.5 0 0 1-2.847-.95l3-9a1.5 1.5 0 0 1 1.898-.948"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default IC_DESKTOP_DEV;
