import React from "react";

function IC_ARROW_RIGHT() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 12h16m0 0-6-6m6 6-6 6"
      ></path>
    </svg>
  );
}

export default IC_ARROW_RIGHT;
