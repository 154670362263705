import React, { useState } from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import BookCall from "./Cards/BookCall";
import ContactForm from "./Cards/Contact_Form";
// import MigrationUsingAccessCodes from "./components/MigrationUsingAccessCodes";
// import MigrationUsingTrialSub from "./components/MigrationUsingTrialSub";
export default function ContactButton(label: any) {
  const theme = useTheme();
  const [method, setMethod] = useState<"GetaQuote" | "BookaCall">("GetaQuote");
  const handleClick = (newMethod: any) => {
    setMethod(newMethod);
  };
  return (
    <Stack alignItems={"center"} spacing={"20px"}>
      {/* <Typography
        sx={{
          fontSize: "40px",
          fontWeight: "700",
          textAlign: "center",
          padding: " 16px 197px",
          [theme.breakpoints.down("tablet")]: {
            fontSize: "30px",
            padding: "0px",
            textAlign: "center",
          },
        }}
      >
        Migrating to Cashifybot
      </Typography> */}
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          background: "#F9FAFB",
          padding: "6px",
          gap: "4px",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          border: " 1px solid  #EAECF0",
          width: "100%",
          //
          [theme.breakpoints.down("desktop")]: {
            alignItems: "start",
            justifyContent: "start",
          },
          //
          [theme.breakpoints.down("tablet")]: {
            alignItems: "start",
            justifyContent: "start",
            padding: "8px",
            gap: "10px",
            width: "90%",
          },
        }}
      >
        <Typography
          onClick={() => handleClick("GetaQuote")}
          sx={{
            width: "50%",
            cursor: "pointer",
            color: method === "GetaQuote" ? "#344054" : "#667085",
            fontWeight: method === "GetaQuote" ? "400" : "400",
            background: method === "GetaQuote" ? "#fff" : "transparent",
            borderRadius: method === "GetaQuote" ? "5px" : "0",
            padding: method === "GetaQuote" ? " 16px 32px" : "16px 32px",
            letterSpacing: "-0.4px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            boxShadow:
              method === "GetaQuote"
                ? "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)"
                : "npne",
            //
            [theme.breakpoints.down("desktop")]: {
              fontSize: method === "GetaQuote" ? "12px" : "12px",
              padding: method === "GetaQuote" ? " 16px 32px" : "16px 32px",
            },
            //
            [theme.breakpoints.down("tablet")]: {
              fontSize: method === "GetaQuote" ? "12px" : "12px",
              padding: method === "GetaQuote" ? "  18px 20px" : "18px 20px",
            },
          }}
        >
          Get a Quote
        </Typography>
        <Typography
          onClick={() => handleClick("BookaCall")}
          sx={{
            width: "50%",
            cursor: "pointer",
            color: method === "BookaCall" ? "#344054" : "#667085",
            fontWeight: method === "BookaCall" ? "400" : "400",
            background: method === "BookaCall" ? "#fff" : "transparent",
            borderRadius: method === "BookaCall" ? "5px" : "0",
            padding: method === "BookaCall" ? " 16px 32px" : " 16px 32px",
            letterSpacing: "-0.4px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            boxShadow:
              method === "BookaCall"
                ? "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)"
                : "npne",
            //
            [theme.breakpoints.down("desktop")]: {
              fontSize: method === "GetaQuote" ? "12px" : "12px",
              padding: method === "GetaQuote" ? " 16px 32px" : "16px 32px",
            },
            //
            [theme.breakpoints.down("tablet")]: {
              fontSize: method === "GetaQuote" ? "12px" : "12px",
              padding: method === "GetaQuote" ? "  18px 20px" : "18px 20px",
            },
          }}
        >
          Book a Call
        </Typography>
      </Stack>
      <AnimatePresence mode="wait">
        {method === "GetaQuote" && <ContactForm />}

        {method === "BookaCall" && <BookCall />}
      </AnimatePresence>
    </Stack>
  );
}
