import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import IC_MINUS from "../pages/home/assets/icons/IC_MINUS";
import IC_PLUS from "../pages/home/assets/icons/IC_PLUS";

export default function ZAccordion(props: {
  active: boolean;
  title: string;
  text: string;
  onChange: (expanded: boolean) => void;
}) {
  return (
    <Stack
      sx={{
        p: "1.5px",
        borderRadius: "8px",
        background: props.active
          ? "linear-gradient(45deg, #f5f5f5, #9a88fb, #f5f5f5, #1570ef)"
          : "#0000",
      }}
    >
      <Stack
        sx={{
          background: props.active ? "#FFF" : "#0000",
          borderRadius: "8px",
        }}
      >
        <Accordion
          elevation={0}
          expanded={props.active}
          onChange={(ev, e) => props.onChange?.(e)}
        >
          <AccordionSummary
            expandIcon={props.active ? <IC_MINUS /> : <IC_PLUS />}
          >
            <Typography
              sx={{
                color: props.active ? "#000" : "#666",
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              {props.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 400,
                color: "#475467",
              }}
            >
              {props.text}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Stack>
  );
}
