import React from "react";

function LG_ZEXWARE_W_T() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M34.832 0c.147.007 1.549 1.344 6.443 6.154 3.435 3.386 6.246 6.199 6.246 6.243 0 .051-.676 2.159-1.505 4.685-.822 2.526-1.526 4.612-1.556 4.634-.036.022-1.629.433-7.03 1.77l5.394 5.383-3.207 11.53-1.057.309c-.58.161-4.278 1.219-8.212 2.335-3.933 1.124-9.46 2.695-17.392 4.957l-6.238-6.19C3.291 38.408.48 35.588.48 35.551s.58-2.504 1.292-5.471c.705-2.967 1.306-5.42 1.336-5.45.029-.021 1.21-.286 2.62-.572 1.416-.287 3.346-.69 4.293-.889.946-.198 1.731-.374 1.746-.396.008-.022-1.32-1.418-2.957-3.107-2.818-2.908-2.965-3.07-2.921-3.283.022-.117.477-2.342 1.005-4.927.529-2.593.976-4.73.998-4.752.022-.03 2.231-.595 4.917-1.263 2.686-.661 7.36-1.829 10.384-2.578 3.031-.756 6.84-1.71 8.476-2.122s3.06-.75 3.163-.742M28 10.252a6850 6850 0 0 0-10.861 2.872c-1.35.36-2.473.675-2.495.697s-.631 2.035-1.365 4.466c-.807 2.687-1.3 4.42-1.248 4.435.044.008 2.165-.411 4.704-.94 2.547-.521 4.697-.97 4.792-.991.125-.03.154-.008.147.103-.014.08-2.532 2.467-5.746 5.441l-5.724 5.303c-1.614 7.358-2.07 9.51-2.047 9.532s3.72-.896 8.233-2.041a13169 13169 0 0 1 13.004-3.298c2.642-.675 4.822-1.241 4.844-1.27.022-.03.719-2.512 1.563-5.516.836-3.003 1.512-5.47 1.504-5.478-.014-.015-2.282.543-5.049 1.226-2.766.69-5.085 1.249-5.151 1.249-.074 0-.132-.044-.132-.096 0-.044 1.328-1.307 2.95-2.798 1.629-1.49 4.33-3.973 6.01-5.508l3.053-2.798c1.16-6.03 1.49-7.806 1.49-7.843 0-.03-.023-.052-.052-.044-.037 0-.947.25-2.04.543-1.086.294-5.76 1.535-10.384 2.754"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default LG_ZEXWARE_W_T;
