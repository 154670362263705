import { Stack, SxProps, Typography, useTheme } from "@mui/material";
import React from "react";

function NetwokCard(props: {
  heading?: string;
  description?: string;
  iconsx?: SxProps;
  img?: any;
}) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        background:
          "linear-gradient(45deg, #f5f5f5, #9a88fb, #f5f5f5, #1570ef)",
        borderRadius: "24px",
        padding: "2px",
        transition: " borderColor 0.3s ease-in-out;",
      }}
    >
      <Stack
        sx={{
          width: "440px",
          height: "296px",
          padding: "72px",
          gap: "28px",
          // border: " 2px solid #1570EF",
          background: "#FFF",
          position: "relative",
          pointerEvents: "none",
          borderRadius: "24px",
        }}
      >
        <Stack
          sx={{
            gap: "28px",
          }}
        >
          <Typography
            sx={{
              fontSize: "32px",
              fontWeight: "500",
              color: theme.palette.grey[200],
              lineHeight: "30px",
              textAlign: "center",
              fontFamily: "outFit",
            }}
          >
            {props.heading}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
            }}
          >
            {props.description}
          </Typography>
        </Stack>
        {/* logos */}
        <Stack
          sx={{
            width: "100px",
            height: "100px",
            background: "#16B364",
            boxShadow: "0px 0px 32.817px 0px rgba(255, 255, 255, 0.35) inset",
            borderRadius: "24px",
            padding: "13px",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            // zIndex: "-1",
            right: " 175px",
            top: "-59px",
            ...props.iconsx,
          }}
        >
          {props.img}
          {/* <LG_ASSISTLY /> */}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default NetwokCard;
