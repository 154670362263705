import React from "react";

function IC_EXTENSION_DEV() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#2E90FA"
        fillRule="evenodd"
        d="M17.915 2.625h.172c3.286 0 5.867 0 7.882.27 2.065.279 3.703.86 4.99 2.146 1.288 1.288 1.869 2.926 2.146 4.991q.088.659.142 1.402c.031.436.047.654-.086.797s-.356.144-.802.144H3.643c-.446 0-.668 0-.802-.144-.133-.143-.117-.361-.086-.797q.054-.743.142-1.402c.278-2.065.858-3.703 2.145-4.99 1.288-1.288 2.926-1.868 4.991-2.146 2.015-.271 4.596-.271 7.882-.271m15.32 12.13c.132.132.133.34.135.759q.006 1.132.005 2.4v.172c0 3.286 0 5.867-.27 7.882-.278 2.065-.86 3.703-2.146 4.99-1.288 1.288-2.926 1.869-4.991 2.146-2.015.271-4.596.271-7.882.271h-.172q-1.269.001-2.4-.005c-.418-.002-.627-.003-.758-.135s-.131-.342-.131-.761V15.525c0-.424 0-.636.132-.768s.344-.132.768-.132h16.949c.42 0 .63 0 .76.13m-21.76-.13c.424 0 .636 0 .768.132s.132.344.132.768v16.833c0 .446 0 .668-.144.802-.143.133-.361.117-.797.086a23 23 0 0 1-1.402-.142c-2.065-.277-3.703-.858-4.99-2.145-1.288-1.288-1.868-2.926-2.146-4.991-.271-2.015-.271-4.596-.271-7.882v-.172q-.001-1.269.005-2.4c.002-.418.003-.627.135-.758s.342-.131.761-.131zm6.9 4.875c0-.621.504-1.125 1.125-1.125h6a1.125 1.125 0 0 1 0 2.25h-6a1.125 1.125 0 0 1-1.125-1.125m1.125 4.875a1.125 1.125 0 0 0 0 2.25h3a1.125 1.125 0 0 0 0-2.25zM9 7.5c0-.828.669-1.5 1.493-1.5h.014C11.33 6 12 6.672 12 7.5S11.331 9 10.507 9h-.014A1.497 1.497 0 0 1 9 7.5M16.493 6C15.67 6 15 6.672 15 7.5S15.668 9 16.493 9h.014C17.33 9 18 8.328 18 7.5S17.331 6 16.507 6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default IC_EXTENSION_DEV;
