import React from "react";

function IC_CHECK() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        stroke="#079455"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.333"
        d="m8.75 14 3.5 3.5 7-7"
      ></path>
    </svg>
  );
}

export default IC_CHECK;
