import React from "react";

function IC_BOT_DEV() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#2E90FA"
        fillRule="evenodd"
        d="M9.667.083a2.917 2.917 0 0 0-1.25 5.553V8.92c-.897.272-1.683.702-2.351 1.393-.996 1.03-1.422 2.322-1.623 3.862-.193 1.489-.193 3.385-.193 5.747v.179c0 2.362 0 4.258.193 5.747.2 1.54.627 2.832 1.623 3.862 1.001 1.036 2.267 1.485 3.775 1.695 1.448.201 3.288.201 5.565.201h1.803c.311-.001 1.102.168 1.773.858a17.4 17.4 0 0 0 1.981 1.868c.743.585 1.653 1.165 2.617 1.398.545.132 1.088.217 1.583.176.524-.043 1.124-.246 1.53-.807.367-.505.415-1.088.401-1.518-.014-.45-.428-2.289-.428-2.289 1.106-.247 2.461-.747 3.268-1.582.996-1.03 1.422-2.322 1.622-3.862.194-1.489.194-3.385.194-5.747v-.179c0-2.362 0-4.258-.194-5.747-.2-1.54-.626-2.832-1.622-3.862-.668-.691-1.454-1.121-2.35-1.393V5.636a2.917 2.917 0 1 0-2.5 0v2.872c-1.253-.091-2.743-.091-4.49-.091h-5.188c-1.747 0-3.237 0-4.49.09V5.637A2.917 2.917 0 0 0 9.667.083m5.078 22.062a1.25 1.25 0 0 0-1.823 1.71c1.211 1.29 3.067 2.062 5.078 2.062s3.867-.771 5.078-2.062a1.25 1.25 0 1 0-1.823-1.71c-.689.733-1.858 1.272-3.255 1.272s-2.566-.539-3.255-1.273m-3.412-5.812c0-.92.743-1.666 1.66-1.666h.014c.917 0 1.66.746 1.66 1.666S13.924 18 13.007 18h-.014c-.917 0-1.66-.746-1.66-1.667m11.66-1.666c-.917 0-1.66.746-1.66 1.666S22.076 18 22.993 18h.015c.916 0 1.659-.746 1.659-1.667 0-.92-.743-1.666-1.66-1.666z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F7F7F7"
        d="M.084 20.5c0-1.596.815-3.001 2.052-3.822.342-.227.513-.34.689-.246s.176.32.176.773v6.59c0 .452 0 .678-.176.772-.176.095-.347-.019-.689-.246A4.58 4.58 0 0 1 .084 20.5M33.444 16.43a4.583 4.583 0 0 1 0 8.138c-.208.109-.444-.051-.444-.286v-7.566c0-.234.236-.394.444-.286"
      ></path>
    </svg>
  );
}

export default IC_BOT_DEV;
