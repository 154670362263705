import React from "react";

function IC_SHAPE() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      fill="none"
      viewBox="0 0 52 52"
    >
      <path
        fill="url(#paint0_linear_22031_29)"
        d="M14.716 21.206a26 26 0 0 0-.644-.009C7.989 21.197.5 25.895.5 25.895s7.489 4.698 13.572 4.698q.282 0 .56-.006-.23.22-.46.45c-4.302 4.302-6.275 12.92-6.275 12.92s8.618-1.974 12.919-6.276q.2-.2.393-.401-.008.32-.008.645c0 6.083 4.698 13.572 4.698 13.572s4.697-7.49 4.697-13.572q0-.284-.006-.561.221.232.45.46c4.302 4.302 12.92 6.276 12.92 6.276s-1.974-8.618-6.275-12.92a31 31 0 0 0-.402-.392q.32.008.645.008c6.083 0 13.572-4.698 13.572-4.698S44.011 21.4 37.928 21.4q-.282 0-.56.006.23-.22.46-.45c4.301-4.301 6.275-12.919 6.275-12.919s-8.618 1.973-12.919 6.275q-.2.2-.393.401.008-.32.008-.645C30.8 7.985 26.101.497 26.101.497s-4.697 7.488-4.697 13.571q0 .283.006.562a26 26 0 0 0-.45-.462C16.657 9.867 8.04 7.894 8.04 7.894s1.974 8.617 6.275 12.919q.201.199.402.393zM26 20.829a5.168 5.168 0 1 1 0 10.335 5.168 5.168 0 0 1 0-10.335"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_22031_29"
          x1="32.249"
          x2="-0.133"
          y1="29.68"
          y2="29.635"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.31"></stop>
          <stop offset="0.768"></stop>
          <stop offset="1"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default IC_SHAPE;
