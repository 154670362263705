import React from "react";

function IC_WEB_DEV() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="34"
      fill="none"
      viewBox="0 0 33 34"
    >
      <path
        fill="#2E90FA"
        d="M24.65 17.375h-7.628c-1.352 0-2.457 0-3.344.096-.922.1-1.74.314-2.458.835-.413.3-.777.665-1.078 1.079-.52.716-.735 1.535-.835 2.457-.096.887-.096 1.992-.096 3.345v.127c0 1.352 0 2.457.096 3.344.1.922.314 1.741.835 2.458.3.413.665.777 1.079 1.078.716.52 1.535.735 2.457.835.887.096 1.992.096 3.344.096h7.628c1.352 0 2.457 0 3.344-.096.922-.1 1.74-.314 2.457-.835.414-.3.778-.665 1.079-1.078.52-.717.735-1.536.835-2.458.096-.887.096-1.992.096-3.344v-.128c0-1.352 0-2.457-.096-3.344-.1-.922-.315-1.74-.835-2.457a4.9 4.9 0 0 0-1.079-1.079c-.716-.52-1.535-.735-2.457-.835-.887-.096-1.992-.096-3.344-.096m-5.835 6.761a1.5 1.5 0 0 0-1.958-2.272l-1.38 1.19-.048.04a8 8 0 0 0-.749.703c-.248.28-.594.765-.594 1.453s.346 1.173.594 1.454c.215.242.502.49.749.702l.048.041 1.38 1.19a1.5 1.5 0 0 0 1.958-2.273l-1.292-1.114zm4.042 0a1.5 1.5 0 0 1 1.958-2.272l1.38 1.19.048.04c.247.213.534.46.749.703.248.28.594.765.594 1.453s-.346 1.173-.594 1.454c-.215.242-.502.49-.749.702l-.048.041-1.38 1.19a1.5 1.5 0 0 1-1.958-2.273l1.292-1.114z"
      ></path>
      <path
        fill="#2E90FA"
        d="M16.407.875h-3.142c-2.665 0-4.816 0-6.509.23-1.759.24-3.239.753-4.411 1.941C1.175 4.231.673 5.721.438 7.492.21 9.202.21 11.377.21 14.082v.21c0 2.704 0 4.88.227 6.59.235 1.77.737 3.26 1.907 4.445.934.947 2.07 1.47 3.4 1.763a1.458 1.458 0 1 0 .632-2.848c-.936-.206-1.51-.517-1.948-.96-.544-.552-.898-1.327-1.092-2.784-.198-1.495-.201-3.477-.201-6.311 0-1.68.001-3.06.043-4.218.015-.394.022-.592.153-.718.13-.126.331-.126.734-.126h21.54c.402 0 .604 0 .734.126.131.126.138.324.153.718.042 1.157.043 2.538.043 4.218a1.46 1.46 0 0 0 1.462 1.458 1.46 1.46 0 0 0 1.463-1.458v-.106c0-2.704 0-4.88-.227-6.589-.235-1.771-.737-3.26-1.907-4.446-1.172-1.188-2.652-1.7-4.412-1.94-1.692-.231-3.843-.23-6.508-.23"
      ></path>
    </svg>
  );
}

export default IC_WEB_DEV;
