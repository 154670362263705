import { Stack, useTheme } from "@mui/material";
import React from "react";
import NetwokCard from "./Cards/NetworkCard";
import LG_ASSISTLY from "./assets/logos/LG_ASSISTLY";
import LG_UPTON from "./assets/logos/LG_UPTON";
import LG_ZEXWARE_W_T from "./assets/logos/LG_ZEXWARE_N_T";
import ColorHeading from "../../components/ColorHeading";
import { IMG_CRICLE1 } from "./assets";
function Network() {
  const theme = useTheme();
  return (
    <Stack
      className="about"
      sx={{
        alignItems: "center",
        gap: "56px",
        zIndex: "3",
        padding: "52px 20px",
        backdropFilter: "blur(12px)",
        background: "rgba(255, 255, 255, 0.50)",
      }}
    >
      <ColorHeading
        text1="Our Collaborative "
        text2="Network"
        description="At Zexware, we believe in the power of collaboration. By working closely with a network of dedicated companies, we enhance our services and bring more tailored solutions to our clients."
        mainsx={{ zIndex: "3" }}
      />

      {/* Cards */}
      <Stack
        sx={{
          height: "550px",
          justifyContent: "flex-end",
          position: "relative",
          // padding: "52px 0",
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            gap: "156px",
            alignItems: "center",
            justifyContent: "center",
            // padding: "52px 0",
          }}
        >
          {" "}
          <NetwokCard
            heading="Zexware"
            img={<LG_ASSISTLY />}
            iconsx={{ background: theme.palette.grey[900] }}
            description="Our team of experts creates custom solutions that perfectly fit your business needs, ensuring maximum success."
          />
          <NetwokCard
            heading="UptoneDesign"
            img={<LG_UPTON />}
            description="We seamlessly integrate advanced technology into your operations to boost efficiency and innovation."
            iconsx={{ background: theme.palette.grey[800] }}
          />
        </Stack>
        {/* images and icons */}
        {/* moon */}
        {/* <Stack
          sx={{
            position: "absolute",
            zIndex: "-2",
            bottom: "-200px",
            right: "-100px",
            overflow: "hidden",
            pointerEvents: "none",
          }}
        >
          <img src={IMG_NET_MOON} alt="" />
        </Stack> */}

        {/* Ellispe1 */}
        <Stack
          sx={{
            position: "absolute",
            zIndex: "-1",
            bottom: "-146px",
            left: "200px",
            pointerEvents: "none",
          }}
        >
          <img src={IMG_CRICLE1} alt="" />
        </Stack>
        {/* Ellispe1 */}
        <Stack
          sx={{
            position: "absolute",
            zIndex: "-1",
            bottom: "-234px",
            left: "200px",
            pointerEvents: "none",
          }}
        >
          <img src={IMG_CRICLE1} alt="" />
        </Stack>

        {/* Ellispe3 */}
        <Stack
          sx={{
            position: "absolute",
            zIndex: "-1",
            bottom: "-310px",
            left: "200px",
            pointerEvents: "none",
          }}
        >
          <img src={IMG_CRICLE1} alt="" />
        </Stack>

        {/* Zexware logo without text */}

        <Stack
          sx={{
            width: "100px",
            height: "100px",
            background: theme.palette.grey[300],
            boxShadow: "0px 0px 32.817px 0px rgba(255, 255, 255, 0.35) inset",
            borderRadius: "24px",
            padding: "12px",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            left: "467px",
            top: "-10px",
          }}
        >
          <LG_ZEXWARE_W_T />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Network;
