import React from "react";

function IC_PLUS() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip0_19924_7144)"
      >
        <path d="M3.75 12h16.5M12 3.75v16.5"></path>
      </g>
      <defs>
        <clipPath id="clip0_19924_7144">
          <path fill="#fff" d="M0 0h24v24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IC_PLUS;
