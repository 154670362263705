import React from "react";
import {
  Checkbox,
  Stack,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import IC_CHECK_BOX from "../pages/home/assets/images/Checkbox.png";
import IC_CHECK_BOX_BASE from "../pages/home/assets/images/Checkboxbase.png";

export default function ZCheckbox(props: {
  onUpdate?: (checked: boolean) => void;
  label?: string;
  value?: boolean;
  typographySx?: TypographyProps;
}) {
  const theme = useTheme();
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Checkbox
        checked={props.value}
        onChange={(c) => props.onUpdate?.(c.target.checked)}
        checkedIcon={<img src={IC_CHECK_BOX_BASE} alt="" />}
        icon={<img src={IC_CHECK_BOX} alt="" />}
      />
      {/* <img
        src={checked ? ICCHECKBOXBASE : ICCHECKBOX}
        alt={checked ? "Checked" : "Unchecked"}
        style={{ marginRight: 8, cursor: "pointer" }}
        onClick={handleChange}
      /> */}
      {props.label && (
        <Typography
          fontSize={"15px"}
          color={props.value ? "#475467" : "#94969C"}
          sx={props.typographySx}
        >
          {props.label}
        </Typography>
      )}
    </Stack>
  );
}
