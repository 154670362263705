import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ColorHeading from "../../components/ColorHeading";
import FeaturesCard from "./Cards/FeaturesCard";
import C_IMG_CASHIFYSELL from "./assets/images/C_IMG_CASHIFYSELL";
import C_IMG_ZEXCORE from "./assets/images/C_IMG_ZEXCORE";
import C_IMG_CBOT from "./assets/images/C_IMg_CASHIFYBOT";
import { IMG_BACKGROUND_EFFECT } from "./assets/images";
function Features() {
  const theme = useTheme();
  const desktop = useMediaQuery("(min-width: 1024px)"); // we add spacings here

  return (
    <Stack
      alignItems={"center"}
      sx={{
        gap: "72px",
        padding: "50px 0px 100px 0px",
        position: "relative",
        zIndex: "0",
      }}
    >
      {/* heading */}
      <Stack
        sx={{
          width: "800px",
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: {
            width: "100%",
            alignItems: "center",
          },
        }}
      >
        <ColorHeading
          text1="Explore Our Powerful "
          text2=" Digital Solution"
          description="
          Cashifysell simplifies how you create, manage, and sell digital
          products. Whether you’re offering templates, software, or digital
          downloads, our platform has the tools you need to reach customers and
          maximize sales."
        />
      </Stack>
      <Stack
        sx={{
          flexDirection: "row",
          gap: "48px",
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: { flexDirection: "column" },
        }}
      >
        <FeaturesCard
          link="https://cashifysell.com"
          img={<C_IMG_CASHIFYSELL />}
          heading="Cashifysell"
          description="Sell digital assets easily using our powerful online store with built-in crypto payments processor."
        />
        <FeaturesCard
          link="https://core-docs.zexware.com"
          img={<C_IMG_ZEXCORE />}
          heading="Zexcore"
          description="Real-time messaging protocol based on WebSocket and Redis 7. "
        />
        <FeaturesCard
          link="https://cashifybot.com"
          img={<C_IMG_CBOT />}
          heading="Cashifybot"
          description="All-in-one solution to your discord and telegram channel subscriptions. Powered by Stripe with built-in crypto payments processor."
        />
      </Stack>
      {desktop && (
        <Stack
          sx={{
            position: "absolute",
            zIndex: "-1",
            width: "100%",
            transform: " rotate(0.24deg)",
            pointerEvents: "none",
            overflow: "hidden",
            top: "-220px",
            right: "0px",
            left: "0",
            [theme.breakpoints.down("desktop")]: {},
            [theme.breakpoints.down("tablet")]: {
              overflow: "hidden",
            },
          }}
        >
          <img
            src={IMG_BACKGROUND_EFFECT}
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </Stack>
      )}
    </Stack>
    //   effect  img
  );
}

export default Features;
