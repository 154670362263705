import React from "react";

function IC_MOB_DEV() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="34"
      fill="none"
      viewBox="0 0 24 34"
    >
      <path
        fill="#2E90FA"
        fillRule="evenodd"
        d="M11.892.5h.216c2.383 0 4.323 0 5.853.206 1.596.214 2.968.677 4.062 1.771.975.975 1.45 2.174 1.694 3.56.236 1.344.273 2.99.281 4.957a1.5 1.5 0 1 1-3 .012c-.008-2-.05-3.397-.236-4.45-.177-1.01-.462-1.559-.86-1.957-.444-.444-1.078-.75-2.341-.92C16.255 3.503 14.517 3.5 12 3.5s-4.254.003-5.56.18c-1.264.169-1.898.475-2.342.919-.398.398-.683.947-.86 1.957-.185 1.053-.228 2.45-.236 4.45a1.5 1.5 0 0 1-3-.012C.01 9.028.047 7.381.283 6.037c.243-1.386.72-2.585 1.694-3.56C3.071 1.383 4.444.92 6.04.706 7.57.5 9.509.5 11.892.5m-10.396 21a1.5 1.5 0 0 1 1.506 1.494c.008 2 .051 3.397.236 4.45.177 1.01.462 1.56.86 1.958.444.444 1.078.75 2.341.92 1.307.175 3.044.178 5.561.178s4.255-.003 5.56-.179c1.264-.17 1.898-.475 2.342-.92.398-.398.683-.947.86-1.957.185-1.053.228-2.45.236-4.45a1.5 1.5 0 1 1 3 .012c-.008 1.967-.045 3.614-.281 4.957-.243 1.386-.719 2.585-1.694 3.56-1.094 1.094-2.466 1.557-4.062 1.772-1.53.205-3.47.205-5.853.205h-.216c-2.383 0-4.323 0-5.852-.205-1.596-.215-2.969-.678-4.063-1.772-.975-.975-1.45-2.174-1.694-3.56-.236-1.343-.273-2.99-.281-4.957A1.5 1.5 0 0 1 1.496 21.5"
        clipRule="evenodd"
      ></path>
      <path
        fill="#2E90FA"
        fillRule="evenodd"
        d="M9.002 27.5a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 0 3h-3a1.5 1.5 0 0 1-1.5-1.5M16.866 13.021a1.5 1.5 0 0 0 .157 2.115l1.84 1.586.32.278-.32.278-1.84 1.586a1.5 1.5 0 1 0 1.958 2.273l1.84-1.586.056-.048c.337-.29.696-.6.958-.896.297-.335.667-.867.667-1.607s-.37-1.271-.667-1.606c-.262-.297-.62-.605-.958-.896l-.056-.048-1.84-1.586a1.5 1.5 0 0 0-2.115.157M7.138 13.021a1.5 1.5 0 0 1-.157 2.115l-1.84 1.586-.32.278.32.278 1.84 1.586a1.5 1.5 0 0 1-1.958 2.273l-1.84-1.586-.056-.048c-.337-.29-.696-.6-.958-.896-.297-.335-.667-.867-.667-1.607s.37-1.271.667-1.606c.262-.297.62-.605.958-.896l.056-.048 1.84-1.586a1.5 1.5 0 0 1 2.115.157M13.977 11.078a1.5 1.5 0 0 1 .948 1.897l-3 9a1.5 1.5 0 1 1-2.846-.949l3-9a1.5 1.5 0 0 1 1.898-.948"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default IC_MOB_DEV;
