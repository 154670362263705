import React from "react";

function IC_LINE() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1"
      height="166"
      fill="none"
      viewBox="0 0 1 166"
    >
      <path stroke="#D1D5DB" d="M.5 0v166"></path>
    </svg>
  );
}

export default IC_LINE;
